import React from "react";
import { GiMeat } from 'react-icons/gi'

const SearchResultSectionLeftMenuFilterOptionsProteins = ({proteins, selectedProteins, setSelectedProteins}) => {
  const [open, setOpen] = React.useState(false);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      setSelectedProteins(selectedProteins.concat([id]))
    } else {
      setSelectedProteins(selectedProteins.filter(row => row !== id))
    }
  }
  return (
    <div className="filter-holder panel-default">
      <div onClick={() => setOpen(!open)} className="filter-heading">
        <h6>
          <GiMeat className="filter-icon" />
          Proteins
        </h6>
      </div>
      <div className={open ? "select-categories" : 'hide-mini select-categories'}>
        <ul className="filter-list cs-checkbox-list">
          {
            proteins?.map((row, index) => {
              return(
                <li key={index}>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={`protein${index}`}
                      className="specials"
                      defaultValue="deals"
                      onChange={(e) => handleChange(e, row.id)}
                    />
                    <label htmlFor={`protein${index}`}>{row.name}</label>
                    {/* <span>(5)</span> */}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SearchResultSectionLeftMenuFilterOptionsProteins;
