import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import BlogCard from '../../components/BlogCard';
import AppSpinner from '../../components/Spinner/Spinner';
// import AppSpinner from '../../components/Spinner/Spinner';
import Banner from '../../components_home/Banner';
import { getBlogById } from '../../redux/actions/blogsActions';
import parse from 'html-react-parser'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function SingleBlog() {
    const dispatch = useDispatch();
    const {blogId} = useParams();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        dispatch(getBlogById(blogId, setLoading))
    }, [])
    const blog = useSelector(state => state?.blogs?.blog?.blog)
    // console.log(blog)
    return (
        <div>
            <div style={{marginBottom: '80px'}}>
                <Banner offers />
            </div>
            {loading ? <div className='p-5'>
                <AppSpinner />
            </div> :
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-section">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="blog-detail">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="author-info">
                                                            <div className="text-holder">
                                                                <ul className="post-options">
                                                                    <li>
                                                                        <i className="icon-clock"></i>
                                                                        <span className="date">{new Date(blog?.created_at).toDateString()}</span>
                                                                    </li>
                                                                  
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="title-area">
                                                            <h2>{blog?.title}
                                                            </h2>
                                                            <span>
                                                                {
                                                                    blog?.description?.length > 3 ?
                                                                    parse(blog?.description) : null
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="main-post">
                                                            <figure><img src={blog?.photo_original_path} alt="#" /></figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                  
            }
        </div>
    )
}

export default SingleBlog