import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppPagination from "../components/Pagination";
import AppSpinner from "../components/Spinner/Spinner";
import {
  getCreators,
  // setCreatorsLoading,
} from "../redux/actions/creatorsActions";
import SearchResultSectionCenterListResult1 from "./SearchResultSectionCenterListMeal";

const SearchResultSectionCenterList = ({
  currentPage,
  setCurrentPage,
  localCart,
  setLocalCart,
  searchKey,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSearch = searchKey?.length > 0;
  const meals = isSearch
    ? useSelector((state) => state?.meals?.meals?.search_result?.data)
    : useSelector((state) => state.meals.meals?.meals?.data);
  const pagesNum = isSearch
    ? useSelector((state) => state.meals?.meals?.search_result?.last_page)
    : useSelector((state) => state.meals?.meals?.meals?.last_page);
  console.log("PAGES", pagesNum);
  // const loading = useSelector((state) => state.meals.loading);
  React.useEffect(() => {
    // dispatch(setCreatorsLoading());
    dispatch(getCreators(currentPage, () => {}));
  }, [currentPage]);
  return (
    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <div className="listing-sorting-holder">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4>{t("NavBar.Meals")}</h4>
          </div>
        </div>
      </div>
      {meals?.length !== 0 ? (
        <div>
          {loading ? (
            <AppSpinner />
          ) : (
            <div className="listing simple">
              <ul>
                {meals?.map((row, index) => (
                  <SearchResultSectionCenterListResult1
                    localCart={localCart}
                    setLocalCart={setLocalCart}
                    meals={meals}
                    key={index}
                    creator={row}
                  ></SearchResultSectionCenterListResult1>
                ))}
              </ul>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <AppPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagesNum={pagesNum}
              />
            </div>
          </div>
        </div>
      ) : (
        <h3 className="sorry-meals-page">
          We're sorry, there's no results for your search or filters.
        </h3>
      )}
    </div>
  );
};

export default SearchResultSectionCenterList;
