import React from "react";
import MealCreatorTopSectionBanner from "./MealCreatorTopSectionBanner";

const MealCreatorTopSection = ({creator}) => {
  return (
    <div
      className="page-section restaurant-detail-image-section"
      style={{
        background:
          "url(/assets/extra-images/cover-photo01.jpg) no-repeat scroll 0 0 / cover"
      }}
    >
      {}
      <div className="container">
        {}
        <div className="row">
          {}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <MealCreatorTopSectionBanner creator={creator}></MealCreatorTopSectionBanner>
          </div>
          {}
        </div>
        {}
      </div>
      {}
    </div>
  );
}

export default MealCreatorTopSection;
