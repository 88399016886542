import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthModalWrapper from "../components/AuthModalWrapper";
import LocalModal from "../components/LocalCartModal";
import AppModal from "../components/Modal";
import { likeMeal } from "../redux/actions/cartActions";

const SearchResultSectionCenterListResult1 = ({
  creator,
  localCart,
  setLocalCart,
}) => {
  const [show, setShow] = React.useState(false);
  const [localShow, setLocalShow] = React.useState(false);
  const [isFav, setIsFav] = React.useState(creator?.is_in_wishlist);
  const [authShow, setAuthShow] = React.useState();
  React.useEffect(() => {
    setIsFav(creator?.is_in_wishlist);
  }, [creator]);
  const dispatch = useDispatch();
  const callBack = () => {};
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  return (
    <div
      style={{ position: "relative" }}
      className="menu-itam-holder product-card-inside"
    >
      <AuthModalWrapper show={authShow} setShow={setAuthShow} />
      <AppModal
        meal={creator}
        mealId={creator?.id}
        show={show}
        setShow={setShow}
      />
      <LocalModal
        localCart={localCart}
        setLocalCart={setLocalCart}
        meal={creator}
        mealId={creator?.id}
        show={localShow}
        setShow={setLocalShow}
      />
      <div className="menu-itam-list">
        <ul style={{ position: "relative" }}>
          {creator?.is_offer ? (
            <span className="discount-span-inside">
              {creator?.discount_percentage}%
            </span>
          ) : null}
          <li>
            <div className="image-holder meal-image-holder">
              {" "}
              <img src={creator.photos?.[0].photo_original_path} alt="#" />
            </div>
            <div className="text-holder meal-text-holder">
              <h6>{creator.name}</h6>
              <span>{creator?.ingredients}</span>
              <a
                style={{ color: "#6e2230" }}
                onClick={() =>
                  logged
                    ? dispatch(likeMeal(creator?.id, isFav, setIsFav, callBack))
                    : setAuthShow(true)
                }
              >
                <i className={isFav ? "icon-heart" : "icon-heart-o"} />{" "}
              </a>
            </div>
            <div className="price-holder">
              <span className="price">{creator.min_price} CA$</span>
              <a
                href="#."
                data-toggle="modal"
                data-target="#extras-0-1"
                className="dev-adding-menu-btn"
                onClick={() => (logged ? setShow(true) : setLocalShow(true))}
              >
                <i className="icon-plus4 text-color" />
              </a>
              <span id="add-menu-loader-0" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SearchResultSectionCenterListResult1;
