import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AppPagination from "../components/Pagination";
import AppSpinner from "../components/Spinner/Spinner";
import {
  creatorsSearch,
  getCreators,
  // setCreatorsLoading,
} from "../redux/actions/creatorsActions";
import SearchResultSectionCenterListResult1 from "./SearchResultSectionCenterListResult1";

const SearchResultSectionCenterList = ({ searchKey }) => {
  console.log("SEARCHKEY", searchKey);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSearch = searchKey?.length > 0;
  const pagesNum = isSearch
    ? useSelector(
        (state) => state?.creators?.creators?.search_result?.last_page
      )
    : useSelector((state) => state.creators.creators?.mealCreators?.last_page);
  const creators = isSearch
    ? useSelector((state) => state.creators?.creators?.search_result?.data)
    : useSelector((state) => state.creators.creators?.mealCreators?.data);
  const [currentPage, setCurrentPage] = React.useState(1);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isSearch) {
        // dispatch(setCreatorsLoading());
        dispatch(creatorsSearch(searchKey, currentPage, 10, setLoading));
      } else {
        setTimeout(() => {
          // dispatch(setCreatorsLoading());
          dispatch(getCreators(currentPage, setLoading));
        }, [100]);
      }
    }, [500]);
    return () => clearTimeout(timer);
  }, [currentPage, pathname, currentPage, searchKey]);
  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchKey]);
  return (
    <div className="col-12">
      <div className="listing-sorting-holder">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4>{t("NavBar.MealCreators")}</h4>
          </div>
        </div>
      </div>
      {creators?.length === 0 ? (
        <h3 className="text-center">{t("Shared.NoResults")}</h3>
      ) : loading ? (
        <AppSpinner />
      ) : (
        <div className="listing simple">
          <ul>
            {creators?.map((row, index) => (
              <SearchResultSectionCenterListResult1
                key={index}
                creator={row}
              ></SearchResultSectionCenterListResult1>
            ))}
          </ul>
        </div>
      )}
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <AppPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagesNum={pagesNum}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchResultSectionCenterList;
