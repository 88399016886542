import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../redux/actions/authActions";
import { myError } from "./Toast";

const ForgetPassword = ({ setForgetPasswordNumber, setStack }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [error, setError] = React.useState();
    const [phone, setPhone] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (phone.length !== 11) {
            myError(t("Toast.Phone number must be 11 charts"))
        } else {
            dispatch(forgetPassword({ phone: phone }, setStack, setError, setLoading))
        }
    }
    React.useEffect(() => { setForgetPasswordNumber(phone) }, [phone])
    console.log("REGISTRATION ERRROR", error)
    return (
        <div>
            <form onSubmit={handleSubmit} method="post" className="wp-user-form demo_test">
                <div className="input-filed">
                    <i className="icon-phone" />
                    <input type="text" name='phone' placeholder={t("Auth.PhoneNumber")} onChange={(e) => setPhone(e.target.value)} />
                    {
                        error?.data?.errors?.phone ?
                            <p className="error-message-login">{error?.data?.errors?.phone[0]}</p> : null
                    }
                </div>
                {/* <div className="input-filed foodbakery-company-name">
                    <i className="icon-v-card" />
                    <input type="text" placeholder="Company Name" />
                </div>
                <div className="input-filed">
                    <i className="icon-v-card" />
                    <input type="text" placeholder="Display Name" />
                </div>
                <div className="input-filed">
                    <i className="icon-email" />
                    <input type="email" placeholder="Email" />
                </div> */}
                {/* <span className="signup-alert">
                    <b>Note :</b> Please enter your correct email and we
                    will send you a password on that email.
                </span> */}
                {/* <div className="checkbox-area">
                    <input
                        type="checkbox"
                        id="terms-head"
                        className="foodbakery-dev-req-field"
                    />
                    <label htmlFor="terms-head">
                        By Registering You Confirm That You Accept theme
                        <a target="_blank" href="#">
                            Terms & conditions
                        </a>
                        And
                        <a target="_blank" href="#">
                            Privacy Policy
                        </a>
                    </label>
                </div> */}
                <div className="side-by-side select-icon clearfix">
                    <div className="select-holder" />
                </div>
                <div className="checks-holder">
                    <button className="auth-button">{loading ? <BiLoaderAlt className="login-loader" /> : t("Auth.SendCode")}</button>
                </div>
            </form>
            <div className="register_content" />
            <div className="create- signin-tab-link ">
                {t("Auth.AlreadyHaveAccount")}
                <a
                    data-toggle="tab"
                    href="#user-login-tab"
                    className="foodbakery-dev-signup-box-btn"
                    onClick={() => setStack('login')}
                > {t("Auth.Login")}
                </a>
            </div>
        </div>
    );
}

export default ForgetPassword;
