import React from "react";
import AuthModalWrapper from "../components/AuthModalWrapper";
import { MdArrowDropDown, MdLogout, MdPerson } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LogoutModal from "../components/LogoutModal";
import { getUser } from "../redux/actions/userActions";
import { getCart, recordOfflineCart } from "../redux/actions/cartActions";
import { useTranslation } from "react-i18next";
import { BiCart } from "react-icons/bi";
import SideDrawer from "../components/SideDrawer/SideDrawer";

const HeaderAuth = () => {
  const { t } = useTranslation();
  const [hide, setHide] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const [logoutShow, setLogoutShow] = React.useState(false);
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const userName = useSelector((state) => state.auth.userName);
  const isCompleted = useSelector((state) => state.auth.isCompleted);
  const localCart = localStorage.getItem("localCart")
    ? JSON.parse(localStorage.getItem("localCart"))
    : [];
  const callBack = () => {
    localStorage.removeItem("localCart");
    dispatch(getCart());
  };
  React.useEffect(() => {
    if (logged) {
      if (localCart?.length > 0) {
        dispatch(recordOfflineCart(callBack));
      }
    }
  }, []);
  console.log(logged, localStorage.getItem("isRegistered"));
  React.useEffect(() => {
    if (logged && localStorage.getItem("isRegistered") === "0") {
      setShow(true);
    } else if (isCompleted === 0) {
      setShow(true);
    } else if (isCompleted === 1) {
      setShow(false);
    }
  }, [useSelector((state) => state.auth.loggedIn)]);
  React.useEffect(() => {
    dispatch(getUser());
  }, []);
  const name = localStorage.getItem("userName");

  return (
    <div className="login-option">
      <AuthModalWrapper show={show} setShow={setShow} />
      <SideDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <LogoutModal show={logoutShow} setShow={setLogoutShow} title="Logout" />
      <button
        className="cs-color cs-popup-joinus-btn login-popup login-button"
        style={{ marginRight: "10px", height: "40px" }}
        onClick={() => setIsDrawerOpen(true)}
      >
        <BiCart style={{ fontSize: "16px" }} />
      </button>
      {!logged ? (
        <button
          className="cs-color cs-popup-joinus-btn login-popup login-button"
          onClick={() => setShow(true)}
          style={{ marginTop: "25px" }}
        >
          {t("Auth.Login")} / {t("Auth.Register")}
        </button>
      ) : (
        <div className="dropdown">
          <button
            onMouseEnter={() => setHide(false)}
            onMouseLeave={() => setHide(true)}
            className="dropbtn flex-around"
          >
            <span>
              {localStorage.getItem("userName") === "null null"
                ? null
                : userName !== ""
                ? userName
                : name.length > 10
                ? name.substring(0, 10) + "..."
                : name}
            </span>{" "}
            <MdArrowDropDown />{" "}
          </button>
          <div
            onMouseEnter={() => setHide(false)}
            onMouseLeave={() => setHide(true)}
            style={
              hide
                ? { height: "1px", visibility: "hidden" }
                : { display: "block" }
            }
            className="dropdown-content"
          >
            <a
              onClick={() => history.push("/profile")}
              className="flex-between"
              href="#"
            >
              <span>{t("Profile.MyProfile")}</span> <MdPerson />
            </a>
            <a
              onClick={() => setLogoutShow(true)}
              className="flex-between"
              href="#"
            >
              <span>{t("Profile.Logout")}</span> <MdLogout />
            </a>
          </div>
        </div>
      )}
      {/* <a className="get-start-btn" href="/">
        Register Restaurant{" "}
      </a> */}
    </div>
  );
};

export default HeaderAuth;
