import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../redux/actions/orderActions";
import SingleOrder from "./SingleOrder";
import AppSpinner from "../../Spinner/Spinner";

function OrdersWrapper() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getOrders(setLoading));
  }, []);
  const orders = useSelector((state) => state.orders?.orders?.current_orders);
  const [currentOrder, setCurrentOrder] = React.useState();
  const { t } = useTranslation();

  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <div style={{ width: "100%" }} className="user-dashboard loader-holder">
        {orders?.length === 0 ? (
          <div className="flex-center" style={{ height: "300px" }}>
            {loading ? (
              <AppSpinner />
            ) : (
              <div>
                <h2 className="sad-text">{t("Profile.EmptyOrders")}</h2>
                <h1 className="text-center">
                  <img style={{ height: "180px" }} src="/assets/emptyBox.png" />
                </h1>
              </div>
            )}
          </div>
        ) : (
          <div className="user-holder">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="element-title has-border right-filters-row">
                  <h5>{t("Profile.MyOrders")}</h5>
                  <div className="right-filters row pull-right">
                    <div className="col-lg-6 col-md-6 col-xs-6">
                      {/* <div className="input-field">
                        <select
                          style={{ fontSize: "12px" }}
                          className="chosen-select-no-single"
                        >
                          <option selected="selected" value="">
                            Select Orders Status
                          </option>
                          <option value="Processing">Processing</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div> */}
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-xs-6">
                                        <div className="input-field">
                                            <i className="icon-angle-down"></i>
                                            <input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range" />
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {loading ? (
                  <AppSpinner />
                ) : (
                  <div className="user-orders-list">
                    <div className="row">
                      {orders?.map((order, index) => {
                        return (
                          <SingleOrder
                            key={index}
                            order={order}
                            orders={orders}
                            setCurrentOrder={setCurrentOrder}
                            currentOrder={currentOrder}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ul className="pagination">
              <li className="active">
                <a>1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <span className="page-numbers dots">…</span>
              </li>
              <li>
                <a href="#">24</a>
              </li>
              <li>
                <a href="#">Next </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrdersWrapper;
