import axios from 'axios';
import * as types from './types';

export const getHomePage = () => dispatch => {
    axios.get(`${types.BASE_URL}/web-home`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.GET_HOME,
                payload: res.data
            })
        })
}


export const setHomeLoading = () => dispatch => {
    dispatch({
        type: types.HOME_LOADING
    })
}