import * as types from "../actions/types";

const initialState = {
  loggedIn: false,
  loading: false,
  userName: "",
  userEmail: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        loggedIn: true,
        isCompleted: action.payload?.user?.is_register_completed,
        loading: false,
        userName:
          action.payload?.user?.first_name +
          " " +
          action.payload?.user?.last_name,
      };

    case types.LOGOUT:
      return {
        ...state,
        loggedIn: "nope",
      };
    case types.COMPLETE_REGISTERATION:
      return {
        ...state,
        loggedIn: true,
        isCompleted: 1,
        loading: false,
        userName: action.payload.firstName + " " + action.payload.lastName,
        userEmail: action.payload.email,
      };
    default:
      return state;
  }
};

export default authReducer;
