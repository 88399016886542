import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { completeRegistration } from "../redux/actions/authActions";

const CompleteRegistration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = React.useState();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConifrmation] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const phone = localStorage.getItem("userPhone");
  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      phone,
    };
    dispatch(completeRegistration(userData, setError, setLoading));
  };
  console.log("ERROR", error ? error?.data?.errors?.first_name : null);
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        method="post"
        className="wp-user-form demo_test"
      >
        <div className="input-filed">
          <input
            type="text"
            placeholder={t("Auth.FirstName")}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {error?.data?.errors?.first_name ? (
            <p className="error-message-login">
              {error?.data?.errors?.first_name[0]}
            </p>
          ) : null}
        </div>
        <div className="input-filed">
          <input
            type="text"
            placeholder={t("Auth.LastName")}
            onChange={(e) => setLastName(e.target.value)}
          />
          {error?.data?.errors?.last_name ? (
            <p className="error-message-login">
              {error?.data?.errors?.last_name[0]}
            </p>
          ) : null}
        </div>
        <div className="input-filed">
          <input
            type="text"
            placeholder={t("Auth.Email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error?.data?.errors?.email ? (
            <p className="error-message-login">
              {error?.data?.errors?.email[0]}
            </p>
          ) : null}
        </div>
        <div className="input-filed">
          <input
            type="password"
            placeholder={t("Auth.Password")}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error?.data?.errors?.password ? (
            <p className="error-message-login">
              {error?.data?.errors?.password[0]}
            </p>
          ) : null}
        </div>
        <div className="input-filed">
          <input
            type="password"
            placeholder={t("Auth.ConfirmPassword")}
            onChange={(e) => setPasswordConifrmation(e.target.value)}
          />
          {error?.data?.errors?.phone ? (
            <p className="error-message-login">
              {error?.data?.errors?.phone[0]}
            </p>
          ) : null}
        </div>
        <div className="side-by-side select-icon clearfix">
          <div className="select-holder" />
        </div>
        <div className="checks-holder">
          <button className="auth-button">
            {loading ? (
              <BiLoaderAlt className="login-loader" />
            ) : (
              t("Auth.Signup")
            )}
          </button>
        </div>
      </form>
      <div className="register_content" />
    </div>
  );
};

export default CompleteRegistration;
