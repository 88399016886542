import React from "react";
import Login from "../components/Login";
import ForgetPassword from "../components/ForgetPasswowrd";
import Registration from "../components/Registeration";

const AuthModal = () => {
  const [stack, setStack] = React.useState('login');
    return (
      <div className="modal fade" id="sign-in" aria-labelledby="myModalLabel">
            <div className="modal-dialog">
                <div className="login-form">
                    <div className="modal-content">
                        <div className="tab-content">
                            <div id="user-login-tab" className="tab-pane fade in active">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h5 className="modal-title foodbakery-dev-login-main-title">
                                        Login To Your Account
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <p className="foodbakery-dev-login-top-msg" />
                                    <div className="cs-login-pbox">
                                        <div className="status status-message" />
                                        {
                                          stack === 'login' ? <Login setStack={setStack} /> :
                                            stack === 'register' ? <Registration setStack={setStack} /> :
                                              stack === 'forget-password' ? <ForgetPassword setStack={setStack} /> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthModal;
