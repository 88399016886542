import React from 'react';
import MealCreatorTopSection from '../../components_mealcreator_info/MealCreatorTopSection';
import MealCreatorCenterSection from '../../components_mealcreator_info/MealCreatorCenterSection';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCreatorById } from '../../redux/actions/creatorsActions';
import { useSelector } from 'react-redux';

export const MealCreatorPage = () => {
  const {creatorId} = useParams();
  const [localCart, setLocalCart] = React.useState(localStorage.getItem('localCart') ? JSON.parse(localStorage.getItem('localCart')) : []);
  const dispatch = useDispatch();
  const creator = useSelector(state => state.creators?.creator?.mealCreator)
  const categories = useSelector(state => state.creators?.creator?.categories)
  React.useEffect(() => {
    dispatch(getCreatorById(creatorId))
  }, [])
  // console.log("LOCALLCARTT", localCart)
  return (
    <>
      <MealCreatorTopSection creator={creator}></MealCreatorTopSection>
      <MealCreatorCenterSection localCart={localCart} setLocalCart={setLocalCart} creator={creator} categories={categories}></MealCreatorCenterSection>
    </>
  );
};
