import React from "react";
// import FooterTopInfoLeft from "./FooterTopInfoLeft";
import FooterTopInfoCuisines from "./FooterTopInfoCuisines";
import FooterTopInfoPages from "./FooterTopInfoPages";
import FooterTopInfoConnect from "./FooterTopInfoConnect";

class FooterTop extends React.Component {
  render() {
    return (
      <div className="footer-widget">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                {/* <FooterTopInfoLeft></FooterTopInfoLeft> */}
                <FooterTopInfoCuisines></FooterTopInfoCuisines>
                <FooterTopInfoPages></FooterTopInfoPages>
                <FooterTopInfoConnect></FooterTopInfoConnect>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterTop;
