import React from "react";
import { useSelector } from "react-redux";
import CartOrderResturant from "../components_old/cart-order-resturant";
import LocalCart from "../components_old/cart-order-resturant/LocalCart";

const MealCreatorCenterSectionPlaceOrder = ({ localCart, setLocalCart }) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  return (
    <div className="sticky-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
      {logged ? (
        <CartOrderResturant />
      ) : (
        <LocalCart localCart={localCart} setLocalCart={setLocalCart} />
      )}
    </div>
  );
};

export default MealCreatorCenterSectionPlaceOrder;
