import React from "react";
import { useTranslation } from "react-i18next";
import { BiEdit, BiLoaderAlt, BiTrash } from "react-icons/bi";
import { GoCreditCard } from "react-icons/go";
import { RiCake3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CheckoutModal from "../../components/CheckoutModal";
import ReusableModal from "../../components/ReusableModal";
import { myError } from "../../components/Toast";
import {
  clearCart,
  editItemQuantity,
  getCart,
  removeCartItem,
} from "../../redux/actions/cartActions";
import {
  DivDiscountCodeMessage,
  OrderCheck,
  OrderCheckTotal,
  OrderPaymentMethods,
  PickupAndDeliveryForm,
  SingleOrder,
  YourOrder,
  YourOrderCart,
  YourOrderCartContent,
  YourOrderCartHeader,
} from "./CartOrderResturantEl";
import Checkout from "./Checkout";

const CartOrderResturant = () => {
  const { t } = useTranslation();
  const [showCheckout, setShowCheckout] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [editShow, setEditShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [editedItem, setEditedItem] = React.useState();
  const [deliverWay, setDeliverWay] = React.useState(0);
  const dispatch = useDispatch();
  const callBack = () => {
    dispatch(getCart());
    setShow(false);
    setDeleteShow(false);
    setEditShow(false);
  };
  const items = useSelector((state) => state.cart.cart?.cart);

  console.log("MY CART ITEMS", items);
  React.useEffect(() => {
    dispatch(getCart());
  }, []);

  React.useEffect(() => {
    setQuantity(editedItem?.quantity);
  }, [editedItem]);

  console.log("CART ITEMS", items);
  const [quantity, setQuantity] = React.useState();
  const confirmationBody = (
    <div>
      <h5 className="text-center">
        {t("Confirmation.Are you sure that you want to clear the cart?")}
      </h5>
      <div className="flex-between mt-4">
        <button onClick={() => setShow(false)} className="clear-no-button">
          NO
        </button>
        <button
          onClick={() => {
            dispatch(clearCart(callBack, setLoading));
          }}
          className="clear-yes-button"
        >
          {loading ? <BiLoaderAlt className="login-loader" /> : "YES"}
        </button>
      </div>
    </div>
  );

  const confirmDeleteItemBody = (
    <div>
      <h5 className="text-center">
        {t("Confirmation.Are you sure that you want to remove the item?")}
      </h5>
      <div className="flex-between mt-4">
        <button
          onClick={() => setDeleteShow(false)}
          className="clear-no-button"
        >
          NO
        </button>
        <button
          onClick={() => {
            dispatch(removeCartItem(callBack, setLoading, deleteId));
          }}
          className="clear-yes-button"
        >
          {loading ? <BiLoaderAlt className="login-loader" /> : "YES"}
        </button>
      </div>
    </div>
  );

  const editQuantityBody = (
    <div>
      <h5 className="edit-quantity-meal-name text-center">
        {editedItem?.meal?.name}
      </h5>
      <div className="flex-between">
        <h3>Quantity</h3>
        <div className="flex-between counter-container">
          <span
            onClick={() => (quantity === 1 ? null : setQuantity(quantity - 1))}
            className="counter-plus"
          >
            <span style={{ marginBottom: "2px" }}>-</span>
          </span>
          <span>{quantity}</span>
          <span
            onClick={() => setQuantity(quantity + 1)}
            className="counter-plus"
          >
            <span style={{ marginBottom: "2px" }}>+</span>
          </span>
        </div>
      </div>
      <button
        onClick={() => {
          dispatch(
            editItemQuantity(callBack, setLoading, editedItem?.id, quantity)
          );
        }}
        className="auth-button submit-edit-quantity"
      >
        {loading ? <BiLoaderAlt className="login-loader" /> : "Submit"}
      </button>
    </div>
  );

  const prices = items?.map((item) => {
    const optionsPrices = item?.cart_options?.map(
      (option) => option?.option?.price
    );
    const optionsTotalPrice = optionsPrices?.reduce((a, b) => a + b, 0);
    console.log("OPTIONS", optionsTotalPrice);
    return (item?.size?.price + optionsTotalPrice) * item?.quantity;
  });
  const subTotal = prices?.reduce((a, b) => a + b, 0)?.toFixed(2);
  const totalPrice = parseInt(subTotal) + parseInt(subTotal) * 0.07;

  const creatorAddress = items ? items[0]?.meal?.meal_creator?.address : null;

  return (
    <>
      <YourOrder>
        <ReusableModal show={show} setShow={setShow} body={confirmationBody} />
        <ReusableModal
          show={editShow}
          setShow={setEditShow}
          body={editQuantityBody}
        />
        <ReusableModal
          show={deleteShow}
          setShow={setDeleteShow}
          body={confirmDeleteItemBody}
        />
        <CheckoutModal
          show={showCheckout}
          setShow={setShowCheckout}
          body={
            <Checkout
              setShow={setShowCheckout}
              deliverWay={deliverWay}
              subTotal={subTotal}
              total={parseInt(subTotal) + parseInt(subTotal) * 0.07}
              creatorAddress={creatorAddress}
            />
          }
          title="Checkout"
        />

        <YourOrderCart>
          <YourOrderCartContent>
            <RiCake3Line />
            <h3>{t("Cart.YourOrder")}</h3>
          </YourOrderCartContent>
          <div>
            <YourOrderCartHeader>
              <DivDiscountCodeMessage>
                <p>{t("Cart.IfYouHaveDiscountCode")}</p>
              </DivDiscountCodeMessage>
              <div>
                <div>
                  <PickupAndDeliveryForm>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          onClick={() => setDeliverWay(2)}
                          value="2"
                          id="pickup"
                          name="order"
                          type="radio"
                        />
                        <label htmlFor="pickup">{t("Cart.PickUp")}</label>
                      </div>
                      <span>15 CA$</span>
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          onClick={() => setDeliverWay(1)}
                          id="delivery"
                          name="order"
                          type="radio"
                        />
                        <label htmlFor="delivery">{t("Cart.Delivery")}</label>
                      </div>
                      <span>15 CA$</span>
                    </div>
                  </PickupAndDeliveryForm>
                </div>
              </div>
            </YourOrderCartHeader>
            {items?.map((item, index) => {
              const optionsPrices = item?.cart_options?.map(
                (option) => option?.option?.price
              );
              const optionsTotalPrice = optionsPrices?.reduce(
                (a, b) => a + b,
                0
              );
              return (
                <div key={index}>
                  <SingleOrder>
                    {/* <div>
                        {item?.meal?.name} <span>6.50 CA$</span>
                      </div> */}
                    <div className="cart-meal-name flex-between">
                      <span>
                        • {item?.size?.name} {item?.meal?.name} x{item.quantity}
                      </span>{" "}
                      <span>
                        {(optionsTotalPrice + item?.size?.price) *
                          item?.quantity}{" "}
                        CA$
                      </span>
                    </div>
                    {/* <span className='flex-between' key={index}>
                                  <span className='bold'>Meal Price</span>  <span>{item?.size?.price} CA$</span>
                      </span> */}
                    <span className="cart-extra-text">Extra</span>
                    <div>
                      {item?.cart_options?.map((option, index) => {
                        return (
                          <span className="flex-between" key={index}>
                            <span>{option?.option?.name}</span>{" "}
                            <span>{option?.option?.price} CA$</span>
                          </span>
                        );
                      })}
                    </div>
                    <div className="flex-between">
                      <BiEdit
                        onClick={() => {
                          setEditShow(true);
                          setEditedItem(item);
                        }}
                        className="edit-item-icon"
                      />
                      <BiTrash
                        onClick={() => {
                          setDeleteShow(true);
                          setDeleteId(item?.id);
                        }}
                        className="edit-item-icon"
                      />
                    </div>
                  </SingleOrder>
                </div>
              );
            })}
            <OrderCheck>
              <div className="flex-between">
                <span>{t("Cart.Subtotal")}</span> <span>{subTotal} CA$</span>
              </div>
              {/* {deliverWay === 0 ? null : deliverWay === 1 ? (
                <div>
                  {t("Cart.Delivery")} <span>15.00 CA$</span>
                </div>
              ) : deliverWay === 2 ? null : null} */}
              <div className="flex-between">
                {t("Cart.Vat")} (13%){" "}
                <span>{(subTotal * 0.07).toFixed(2)} CA$</span>
              </div>
              <OrderCheckTotal>
                <div className="flex-between">
                  {t("Cart.Total")} <span>{totalPrice?.toFixed(2)} CA$</span>
                </div>
              </OrderCheckTotal>
              {/* <div>There are no items in your basket.</div> */}
            </OrderCheck>
            <OrderPaymentMethods>
              <div>
                <GoCreditCard />
                <span>card</span>
              </div>
            </OrderPaymentMethods>
            <div className="flex-between">
              <span />
              <span onClick={() => setShow(true)} className="clear-cart">
                {t("Cart.ClearCart")}
              </span>
            </div>
            <button
              disabled={items?.length === 0 ? true : false}
              onClick={() =>
                deliverWay === 0
                  ? myError(t("Toast.Please choose deliver way first"))
                  : setShowCheckout(true)
              }
              className="auth-button confirm-order-button"
            >
              {t("Cart.ConfirmOrder")}
            </button>
          </div>
        </YourOrderCart>
      </YourOrder>
    </>
  );
};

export default CartOrderResturant;
