import React from "react";
import { useTranslation } from "react-i18next";
import MobileAppSectionForm from "./MobileAppSectionForm";

const MobileAppSectionRight = () => {
  const {t} = useTranslation();
  return (
    <div className="column-text">
      <h3
        style={{
          color: "#000000 !important"
        }}
      >
        {t("Footer.XChefInYourMobile")}
      </h3>
      <div className="promo-content">
        {t("Footer.GetOurApp")}
      </div>
      <a className="app-btn" href="#">
        <img src="/assets/extra-images/app-img1-1.png" alt="" />
      </a>
      <a className="app-btn" href="#">
        <img src="/assets/extra-images/app-img2-1.png" alt="" />
      </a>
      <MobileAppSectionForm></MobileAppSectionForm>
    </div>
  );
}

export default MobileAppSectionRight;
