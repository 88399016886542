import React from "react";
import Header from "./Header";
import MainSection from "./MainSection";
import Footer from "./Footer";

const AppWrapper = ({children}) => {
    return (
      <div className="wrapper">
        <Header></Header>
        <MainSection>{children}</MainSection>
        <Footer></Footer>
      </div>
    );
}

export default AppWrapper;
