import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/actions/types";
import { myError, myToast } from "../../Toast";

function ChangePassword() {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState();
  const [displayError, setDisplayError] = React.useState("");

  const changePassword = () => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/user/update-password`,
        {
          old_password: currentPassword,
          password: newPassword,
          password_confirmation: newPasswordConfirm,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setDisplayError("");
        dispatch({ type: "PP" });
        setLoading(false);
        myToast(t("Toast.Password has been changed successfully"));
        setErr("");
      })
      .catch((err) => {
        setErr(err.response.data);
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (newPassword.length < 6) {
      myError(t("Toast.Password must be more than 6 charts"));
      setDisplayError(t("Toast.Password must be more than 6 charts"));
    } else if (newPassword !== newPasswordConfirm) {
      myError(t("Toast.Password doesn't match confirmation"));
      setDisplayError(t("Toast.Password doesn't match confirmation"));
    } else {
      dispatch(changePassword());
    }
  };

  console.log("ERR", err);
  return (
    <div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="element-title has-border">
          <h5>{t("Profile.ChangePassword")}</h5>
        </div>
      </div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <label> {t("Profile.CurrentPassword")}*</label>
          <input
            onChange={(e) => setCurrentPassword(e.target.value)}
            type="password"
          />
          <span className="red">
            {err?.errors?.password ? err?.errors?.password[0] : null}
          </span>
        </div>
      </div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <label> {t("Profile.NewPassword")}*</label>
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
          />
        </div>
      </div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <label> {t("Profile.ConfirmNewPassword")}* </label>
          <input
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            type="password"
          />
        </div>
      </div>
      <div className="red">{displayError}</div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <button
            onClick={() => handleSubmit()}
            name="button"
            type="button"
            className="btn-submit"
          >
            {loading ? (
              <BiLoaderAlt className="login-loader" />
            ) : (
              t("Profile.ChangePassword")
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
