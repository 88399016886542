import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReusableModal from "../../ReusableModal";
import AddAddress from "./AddAddress";
import AddressCard from "./AddressCard";
function Addresses() {
  const { t } = useTranslation();
  const addresses = useSelector((state) => state?.user?.user?.user?.addresses);
  console.log("ADDRESSES", addresses);
  const [show, setShow] = React.useState();
  const [lng, setLng] = React.useState(0);
  const [lat, setLat] = React.useState(0);
  const defaultLocation = { lat: lat, lng: lng };
  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }, []);
  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <ReusableModal
        show={show}
        setShow={setShow}
        title={t("Profile.AddAddress")}
        body={
          <AddAddress defaultLocation={defaultLocation} setShow={setShow} />
        }
      />
      <div style={{ width: "100%" }} className="user-dashboard loader-holder">
        <div className="user-holder">
          <div className="flex-between mb-2">
            <h4 className="your-registered-text">
              {t("Profile.YourRegisteredAddresses")}
            </h4>
            <button
              onClick={() => setShow(true)}
              className="add-address-button"
            >
              {t("Cart.AddNewAddress")}
            </button>
          </div>
          <div className="row">
            {addresses?.length === 0 ? (
              <div style={{ height: "200px" }} className="flex-center">
                <h4>{t("Profile.YouHaveNoAddresses")}</h4>
              </div>
            ) : (
              addresses?.map((address, index) => {
                return <AddressCard address={address} key={index} />;
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addresses;
