import React from "react";
import FooterTop from "./FooterTop";
import FooterBottom from "./FooterBottom";

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="footer-style-1">
        <FooterTop></FooterTop>
        <FooterBottom></FooterBottom>
      </footer>
    );
  }
}

export default Footer;
