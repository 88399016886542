import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../I18next";
import SideDrawer from "../components/SideDrawer/SideDrawer";
import { BiCart } from "react-icons/bi";

const MobileNav = ({ setHide }) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  return (
    <>
      <SideDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <ul>
        <li>
          <Link onClick={() => setHide(true)} to="/">
            XChef
          </Link>
          {/*<ul>*/}
          {/*  <li>*/}
          {/*    <a href="/">Food Bakery</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="/">Mexican Restaurant</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="/">Food Stop</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="/">Rtl Demo</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="/">Food Court</a>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <a href="/">Single Restaurant Demo</a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </li>
        <li>
          <Link onClick={() => setHide(true)} to="/meal-creators">
            {t("NavBar.MealCreators")}
          </Link>
        </li>
        <li>
          <Link onClick={() => setHide(true)} to="/meals">
            {t("NavBar.Meals")}
          </Link>
        </li>
        <li>
          <Link onClick={() => setHide(true)} to="/offers">
            {t("NavBar.Offers")}
          </Link>
        </li>

        <li>
          <Link onClick={() => setHide(true)} to="/blogs">
            {t("NavBar.Blogs")}
          </Link>
        </li>
        {localStorage.getItem("i18nextLng") !== "fr" ? (
          <li
            onClick={() => {
              i18n.changeLanguage("fr");
              window.location.reload();
            }}
          >
            <a>FR</a>
          </li>
        ) : (
          <li
            onClick={() => {
              i18n.changeLanguage("en");
              window.location.reload();
            }}
          >
            <a>EN</a>
          </li>
        )}
        {/* <li >
              <a href="#">Blogs</a>
            </li> */}
        <li>
          {" "}
          <BiCart
            className="nav-cart-mobile"
            onClick={() => setIsDrawerOpen(true)}
          />
        </li>
      </ul>
    </>
  );
};

export default MobileNav;
