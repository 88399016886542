import React from "react";


class MainSection extends React.Component {
  render() {
    return (
      <div className="main-section ">
        {this.props.children}
      </div>
    );
  }
}

export default MainSection;
