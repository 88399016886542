import React from "react";

class MobileAppSectionInfoLeft extends React.Component {
  render() {
    return (
      <div className="img-frame">
        <figure>
          <img style={{maxHeight: '350px'}} src="/assets/extra-images/mockup.png" alt=""/>
        </figure>
      </div>
    );
  }
}

export default MobileAppSectionInfoLeft;
