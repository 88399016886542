import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomePage } from "../redux/actions/homeActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterTopInfoCuisines = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatch(getHomePage());
  }, []);
  const creators = useSelector((state) => state.home?.mealCreators);
  console.log(creators);
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <div className="widget widget-categories">
        <div className="flex-center">
          <Link to="/">
            <img
              style={{ height: "100px", marginTop: "50px" }}
              src="/assets/extra-images/xchif.png"
              alt="XChef"
            />
          </Link>
        </div>
        <b
          style={{
            fontSize: "16px",
            textTransform: "uppercase",
            textAlign: "center",
            display: "block",
            marginBottom: "30px",
            letterSpacing: "6px",
            color: "rgb(255, 255, 255)",
            marginTop: "20px",
          }}
        >
          {t("NavBar.OrganicFast")}
          <br />
          {t("NavBar.EasyHealthy")}
        </b>
      </div>
    </div>
  );
};

export default FooterTopInfoCuisines;
