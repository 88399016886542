import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/actions/types";
import { myError, myToast } from "../../Toast";

function OrderDetails({ order }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [theIndex, setIndex] = React.useState(100000);
  const [rate, setRate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [mealId, setMealId] = React.useState("");
  const [err, setErr] = React.useState();
  console.log(err);
  const sendRating = () => (dispatch) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("rate", rate);
    formData.append("comment", comment);
    formData.append("meal_id", mealId);
    axios
      .post(`${BASE_URL}/user/meal-add-or-update-review`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        setErr([]);
        setIndex(10000);
        setLoading(false);
        myToast(t("Toast.Your rate has been sent successfully"));
        dispatch({
          type: "R",
        });
      })
      .catch((err) => {
        console.log("err", err.response?.data?.errors);
        setErr(err.response?.data?.errors);
        setLoading(false);
      });
  };
  console.log(rate);
  const handleSubmit = () => {
    if (rate === "") {
      myError(t("Toast.Choose stars rate first"));
    } else {
      dispatch(sendRating());
    }
  };
  return (
    <div>
      <div className="modal-body">
        <div className="order-detail-inner">
          <div className="description-holder">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="list-detail-options has-checkbox">
                  <h3>{order?.meal_creator?.name} </h3>
                  <ul className="order-detail-options">
                    <li className="order-number">
                      <strong>{t("Orders.OrderId")}:</strong>
                      <span>{order?.id}</span>
                    </li>
                    <li className="req-delivery">
                      <strong>{t("Orders.DeliverTime")}:</strong>
                      <span>
                        {order?.meal_creator?.delivery_from +
                          " Mins ~ " +
                          order?.meal_creator?.delivery_to +
                          " Mins"}{" "}
                      </span>
                    </li>
                    <li className="created-date">
                      <strong>{t("Orders.OrderDate")}:</strong>
                      <span>{new Date(order?.created_at).toDateString()} </span>
                    </li>
                    {/* <li className="order-type">
                                          <strong>Type:</strong>
                                          <span>order</span>
                                      </li> */}
                    {/* <li className="order-type">
                                            <strong>Payment Status:</strong>
                                            <span>Approved</span>
                                        </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="customer-detail-holder">
                  <h3>{t("Orders.CustomerDetails")}</h3>
                  <ul className="customer-detail">
                    <li>
                      <strong>{t("Orders.Name")} :</strong>
                      <span>{order?.first_name + " " + order?.last_name}</span>
                    </li>
                    <li>
                      <strong>{t("Orders.PhoneNumber")} :</strong>
                      <span>{order?.phone}</span>
                    </li>
                    {/* <li>
                                          <strong>Email :</strong>
                                          <span>dum4@chimpgroup.com</span>
                                      </li> */}
                    <li>
                      <strong>{t("Orders.Address")} :</strong>
                      <span>London</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="order-status-holder">
                  <div className="order-status-process order-status">
                    <p stoole="background:#047a06;">Your order is completed </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h2 className="heading">{t("Orders.FoodMenu")}</h2>
                <div className="responsive-table">
                  <ul className="categories-order table-generic">
                    <li className="order-heading-titles">
                      <div>{t("Orders.Product")}</div>
                      <div>{t("Orders.Quantity")}</div>
                      <div>{t("Orders.Rate")}</div>
                      <div>{t("Orders.Price")}</div>
                    </li>
                    {order?.items?.map((item, index) => {
                      const optionsPrices = item?.item_options?.map(
                        (option) => option?.price
                      );
                      const optionsTotalPrice = optionsPrices?.reduce(
                        (a, b) => a + b,
                        0
                      );
                      console.log("OPTIONS", item?.item_options);
                      return (
                        <>
                          <li
                            key={index}
                            item={item}
                            className="order-heading-titles"
                          >
                            <div>
                              <h4>{item?.name}</h4>
                              <h5>Foodbakery Special 9" Deep Pan</h5>
                            </div>
                            <div>{item?.quantity}</div>
                            <div
                              className="pointer bold"
                              onClick={() => {
                                index === theIndex
                                  ? setIndex(100000)
                                  : setIndex(index);
                                setMealId(item?.meal_id);
                              }}
                            >
                              {t("Orders.RateNow")}
                            </div>
                            <div>
                              <span className="category-price text-center">
                                {item?.quantity *
                                  (item?.price + optionsTotalPrice)}{" "}
                                CA$
                              </span>
                            </div>
                          </li>
                          <li
                            key={index}
                            item={item}
                            className={`order-heading-titles ${
                              index === theIndex ? "" : "hide-meal-rate"
                            }`}
                          >
                            <div>
                              <input
                                onChange={(e) => setComment(e.target.value)}
                                className="form-control"
                                placeholder="Write your review..."
                              />
                            </div>
                            <div></div>
                            {/* <div>{item?.quantity}</div> */}
                            <div
                              className="flex-center"
                              style={{ direction: "rtl", width: "150px" }}
                            >
                              <div className="stars-meal">
                                <input
                                  onClick={(e) => setRate(e.target.value)}
                                  type="radio"
                                  id="five"
                                  name="rate"
                                  value="5"
                                />
                                <label htmlFor="five"></label>
                                <input
                                  onClick={(e) => setRate(e.target.value)}
                                  type="radio"
                                  id="four"
                                  name="rate"
                                  value="4"
                                />
                                <label htmlFor="four"></label>
                                <input
                                  onClick={(e) => setRate(e.target.value)}
                                  type="radio"
                                  id="three"
                                  name="rate"
                                  value="3"
                                />
                                <label htmlFor="three"></label>
                                <input
                                  onClick={(e) => setRate(e.target.value)}
                                  type="radio"
                                  id="2"
                                  name="rate"
                                  value="2"
                                />
                                <label htmlFor="2"></label>
                                <input
                                  onClick={(e) => setRate(e.target.value)}
                                  type="radio"
                                  id="one"
                                  name="rate"
                                  value="1"
                                />
                                <label htmlFor="one"></label>
                              </div>
                            </div>
                            <div>
                              <button
                                onClick={() => handleSubmit()}
                                className="submit-meal-review"
                              >
                                {loading ? (
                                  <BiLoaderAlt className="login-loader" />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <h3>
                      {t("Orders.OrderTotal")}: {order?.total}
                    </h3>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <ul className="order-detail-options order-total">
                      <li className="created-date">
                        <strong>{t("Cart.Subtotal")}:</strong>
                        <span>{order?.subtotal?.toFixed(2)} CA$</span>
                      </li>
                      <li className="order-type">
                        <strong>{t("Cart.Delivery")}: </strong>
                        <span>£{order?.delivery_fees}</span>
                      </li>
                      <li className="order-type">
                        <strong>{t("Cart.Vat")}</strong>
                        <span>£{order?.vat}</span>
                      </li>
                      <li className="order-type total-price">
                        <strong>{t("Cart.Total")}:</strong>
                        <span>{order?.total?.toFixed(2)} CA$</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
