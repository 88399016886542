import React from 'react'

function SingleReview() {
  return (
      <li className="alert ">
          <div className="list-holder">
              <div className="review-text">
                  <div className="review-title">
                      <h6><a href="# "> Restaurant Demo: Delicious and Wealthy </a></h6>
                      <div className="rating-holder">
                          <div className="rating-star">
                              <span className="rating-box" style={{ width: '100%' }}></span>
                          </div>
                      </div>
                  </div>
                  <em className="review-date">21 mins Ago </em>
                  <p className="more">A+++ support from developers. Super system, all integrated very well documented and great support, this is great. </p>
              </div>
              <a href="#" className="delete-this-user-review close"><i className="icon-close2"></i></a>
          </div>
      </li>
  )
}

export default SingleReview