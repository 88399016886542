import * as types from '../actions/types'

const initialState = {
    orders: [],
    loading: false,
    likes: []
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            };
        case types.GET_LIKES:
            return {
                ...state,
                likes: action.payload,
                loading: false
            };

        default:
            return state
    }

}

export default ordersReducer;