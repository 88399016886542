import React from "react";
import MealCreatorCenterSectionTabsHolderTab1MenuList from "./MealCreatorCenterSectionTabsHolderTab1MenuList";

const MealCreatorCenterSectionTabsHolderTab1 = ({localCart, setLocalCart, categories}) => {
  return (
    <div id="home" className="tab-pane fade in active">
      <div className="menu-itam-holder">
        {/* <div className="field-holder sticky-search">
          <input
            id="menu-srch-6272"
            data-id={6272}
            className="input-field dev-menu-search-field"
            type="text"
            placeholder="Search food item"
          />
        </div> */}
        <MealCreatorCenterSectionTabsHolderTab1MenuList localCart={localCart} setLocalCart={setLocalCart} categories={categories}></MealCreatorCenterSectionTabsHolderTab1MenuList>
      </div>
    </div>
  );
}

export default MealCreatorCenterSectionTabsHolderTab1;
