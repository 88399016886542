import React from "react";

const MealCreatorCenterSectionTabsHolderTab1MenuListCategory1 = ({category}) => {
  return (
    <div className="element-title" id="menu-category-0">
      <h5 className="text-color">{category?.name}</h5>
      {/* <span>
        Choose your cut: Triangular, square, fingers or Un cut on any size
        pizza.
      </span> */}
    </div>
  );
}

export default MealCreatorCenterSectionTabsHolderTab1MenuListCategory1;