import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux'
import { BASE_URL } from '../../../redux/actions/types';
import { getUser } from '../../../redux/actions/userActions';
import { myToast } from '../../Toast';
import {BiLoaderAlt} from 'react-icons/bi'
import { useTranslation } from 'react-i18next';

function ChangeName() {
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(false)
    const [firstName, setFirstName] = React.useState(localStorage.getItem('firstName'))
    const [secondName, setSecondName] = React.useState(localStorage.getItem('secondName'))
    const dispatch = useDispatch();
    const callBack = () => {
        dispatch(getUser())
    }
    const sendRequest = () => dispatch => {
        setLoading(true)
        axios.post(`${BASE_URL}/user/update-informations`, {first_name: firstName, last_name: secondName}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(() => {
            setLoading(false)
            dispatch({type: 'K'})
            myToast(t("Toast.Name has been updated"))
            callBack()
        })
        .catch(() => setLoading(false))
    }
    const handleSubmit = () => {
        dispatch(sendRequest())
    }
    return (
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="field-holder">
                    <label>{t("Auth.FirstName")}</label>
                    <input defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" className="foodbakery-dev-req-field" />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="field-holder">
                    <label>{t("Auth.LastName")}</label>
                    <input defaultValue={secondName} onChange={(e) => setSecondName(e.target.value)} type="text" className="foodbakery-dev-req-field" />
                </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div className="field-holder">
                    <button onClick={() => handleSubmit()} name="button" type="button" className="btn-submit">{loading ? <BiLoaderAlt className='login-loader' /> : t("Profile.ChangeName")}</button>
                </div>
            </div>

        </div>
    )
}

export default ChangeName