import axios from "axios";
import React from "react";
import MapPicker from "react-google-map-picker";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/actions/types";
import { getUser } from "../../../redux/actions/userActions";
import { myToast } from "../../Toast";
import { BiLoaderAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";

function EditAddress({ address, setShow }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [area, setArea] = React.useState(address?.area);
  const [street, setStreet] = React.useState(address?.street);
  const [buildingType, setBuildingType] = React.useState(
    address?.building_type
  );
  const [building, setBuilding] = React.useState(address?.building_number);
  const [villaNumber, setVillaNumber] = React.useState(address?.villa_number);
  const [floor, setFloor] = React.useState(address?.floor_number);
  const [apartment, setApartment] = React.useState(address?.apartment_number);
  const [zoom, setZoom] = React.useState(16);
  const [location, setLocation] = React.useState({
    lng: Number(address.long),
    lat: Number(address.lat),
  });

  const [loading, setLoading] = React.useState(false);
  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }
  const villaData = new FormData();
  villaData.append("area", area);
  villaData.append("street", street);
  villaData.append("building_type", buildingType);
  villaData.append("villa_number", villaNumber);
  villaData.append("lat", location.lat);
  villaData.append("long", location.lng);

  const buildingData = new FormData();
  buildingData.append("area", area);
  buildingData.append("street", street);
  buildingData.append("building_type", buildingType);
  buildingData.append("building_number", building);
  buildingData.append("floor_number", floor);
  buildingData.append("apartment_number", apartment);
  buildingData.append("lat", location.lat);
  buildingData.append("long", location.lng);
  const addAddress = () => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/user/update/address/${address?.id}`,
        buildingType == "1" ? villaData : buildingData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        dispatch(getUser());
        myToast(t("Profile.AddressUpdated"));
        dispatch({ type: "m" });
        setShow(false);
      });
  };
  console.log("LOCA", location);
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }
  const handleSubmit = () => {
    dispatch(addAddress());
  };
  console.log("LOCATION", location);
  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-6">
          <label>{t("Profile.Area")}</label>
          <input
            onChange={(e) => setArea(e.target.value)}
            autoFocus
            placeholder={t("Profile.Area")}
            className="add-address-input form-control"
            defaultValue={address?.area}
          />
        </div>
        <div className="col-md-6 col-6">
          <label>{t("Profile.Street")}</label>
          <input
            onChange={(e) => setStreet(e.target.value)}
            placeholder={t("Profile.Street")}
            className="add-address-input form-control"
            defaultValue={address?.street}
          />
        </div>
        <div className="col-md-6 col-6">
          <label>{t("Profile.BuildingType")}</label>
          <select
            onChange={(e) => setBuildingType(e.target.value)}
            className="add-address-input form-control"
            defaultValue={address?.building_type}
          >
            <option value="2">{t("Profile.Building")}</option>
            <option value="1">{t("Profile.Villa")}</option>
          </select>
        </div>
        {buildingType == "1" ? (
          <div className="col-md-6 col-6">
            <label>{t("Profile.VillaNumber")}</label>
            <input
              onChange={(e) => setVillaNumber(e.target.value)}
              placeholder={t("Profile.VillaNumber")}
              className="add-address-input form-control"
              defaultValue={address?.villa_number}
            />
          </div>
        ) : buildingType == "2" ? (
          <>
            <div className="col-md-6 col-6">
              <label>{t("Profile.BuildingNumber")}</label>
              <input
                onChange={(e) => setBuilding(e.target.value)}
                placeholder={t("Profile.BuildingNumber")}
                className="add-address-input form-control"
                defaultValue={address?.building_number}
              />
            </div>
            <div className="col-md-6 col-6">
              <label>{t("Profile.Floor")}</label>
              <input
                onChange={(e) => setFloor(e.target.value)}
                placeholder={t("Profile.Floor")}
                className="add-address-input form-control"
                defaultValue={address?.floor_number}
              />
            </div>
            <div className="col-md-6 col-6">
              <label>{t("Profile.Apartment")}</label>
              <input
                onChange={(e) => setApartment(e.target.value)}
                placeholder={t("Profile.Apartment")}
                className="add-address-input form-control"
                defaultValue={address?.apartment_number}
              />
            </div>
          </>
        ) : null}
        <MapPicker
          defaultLocation={location}
          zoom={zoom}
          mapTypeId="roadmap"
          style={{ height: "200px" }}
          onChangeZoom={handleChangeZoom}
          onChangeLocation={handleChangeLocation}
          apiKey="AIzaSyDi_vwLtn6Te8HoYYsrmvELA2zZC4QIxfM"
        />
        <button onClick={() => handleSubmit()} className="auth-button">
          {loading ? (
            <BiLoaderAlt className="login-loader" />
          ) : (
            t("Profile.UpdateAddress")
          )}
        </button>
      </div>
    </div>
  );
}

export default EditAddress;
