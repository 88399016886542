import React from "react";

const MealCreatorCenterSectionCategoriesList = ({categories}) => {
  return (
    <div className="filter-wrapper">
      <div className="categories-menu">
        <h6>
          <i className="icon-restaurant_menu" />
          Categories
        </h6>
        <ul className="menu-list">
          {
            categories?.map((cat, index) => {
              return(
                <li key={index} className="active">
                  <a href="#" className="menu-category-link">
                    {" "}
                    {cat.name}{" "}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default MealCreatorCenterSectionCategoriesList;
