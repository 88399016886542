import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderCheck, OrderCheckTotal } from "./CartOrderResturantEl";
import { myError, myToast } from "../../components/Toast";
import { BiLoaderAlt, BiCheck } from "react-icons/bi";
import { BASE_URL } from "../../redux/actions/types";
import { getCart } from "../../redux/actions/cartActions";
import AddAddress from "../../components/Profile/Addresses.js/AddAddress";
import { useTranslation } from "react-i18next";

function Checkout({ setShow, deliverWay, subTotal, creatorAddress }) {
  const { t } = useTranslation();
  const zones = useSelector((state) => state.user?.zones);
  const mealCreatorId = useSelector(
    (state) => state.cart?.cart?.cart[0]?.meal?.meal_creator_id
  );
  console.log("meal creatorId", mealCreatorId);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const [promoLoading, setPromoLoading] = React.useState(false);
  const [err, setErr] = React.useState();
  const [applied, setApplied] = React.useState(false);
  const addresses = useSelector((state) => state?.user?.user?.user?.addresses);
  const user = useSelector((state) => state?.user?.user?.user);
  const [firstName, setFirstName] = React.useState(user?.first_name);
  const [lastName, setLastName] = React.useState(user?.last_name);
  const [phone, setPhone] = React.useState(user?.phone);
  const [address, setAddress] = React.useState(0);
  const [useWallet, setUseWallet] = React.useState(0);
  const [addAddress, setAddAddress] = React.useState(false);
  const [zone, setZone] = React.useState(0);
  const [theSub, setTheSub] = React.useState(subTotal);
  const [succPromo, setSuccPromo] = React.useState("");
  const total = parseInt(theSub) + parseInt(theSub) * 0.07;
  const totalBefore = parseInt(subTotal) + parseInt(subTotal) * 0.07;
  const [code, setCode] = React.useState();
  const [currentZone, setCurrentZone] = React.useState({ fees: 0 });
  React.useEffect(() => {
    if (parseInt(zone) !== 0) {
      setCurrentZone(zones?.find((item) => item?.id === parseInt(zone)));
    }
  }, [zone]);
  console.log(theSub, subTotal, "SUBTOTALS");
  const discounted = parseInt(theSub) === parseInt(subTotal) ? false : true;
  console.log(address, total);
  const applyPromo = () => (dispatch) => {
    setPromoLoading(true);
    axios
      .post(
        `${BASE_URL}/user/apply-coupon`,
        { subtotal: parseInt(subTotal), code: code },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "DD" });
        setTheSub(res.data?.total);
        setPromoLoading(false);
        setErr("");
        setApplied(true);
        setSuccPromo(code);
      })
      .catch(() => {
        setPromoLoading(false);
        setErr("Invalid promo code");
      });
  };
  console.log(zones, "ZONES");
  const checkout = () => (dispatch) => {
    setLoading(true);
    const formData = new FormData();
    deliverWay === 1 ? formData.append("address_id", address) : null;
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("receiving_way", deliverWay);
    formData.append("pay_using_wallet", useWallet);
    formData.append("zone_id", zone);
    formData.append("meal_creator_id", mealCreatorId);
    succPromo === "" ? null : formData.append(`code`, succPromo);
    // formData.append('subtotal', parseInt(theSub))
    formData.append("total", 0);
    axios
      .post(`${BASE_URL}/user/checkout`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        setShow(false);
        setLoading(false);
        dispatch({ type: "K" });
        myToast(t("Toast.Order has been placed successfully"));
        dispatch(getCart());
      })
      .catch((err) => {
        console.log("error");
        setLoading(false);
        myError(err?.response?.data?.message);
      });
  };

  const handleSubmit = () => {
    if (address === 0 && deliverWay === 1) {
      myError("Please choose address first");
      return;
    } else if (parseInt(zone) === 0) {
      myError("Please choose zone first");
    } else {
      dispatch(checkout());
    }
  };

  const handleUseWalletChange = (e) => {
    if (e.target.checked) {
      setUseWallet(1);
    } else {
      setUseWallet(0);
    }
  };
  const [lng, setLng] = React.useState(0);
  const [lat, setLat] = React.useState(0);
  const price = (total + currentZone?.fees).toFixed(2);

  const defaultLocation = { lat: lat, lng: lng };
  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }, []);
  return addAddress ? (
    <>
      <h3>Add Address</h3>
      <AddAddress defaultLocation={defaultLocation} setShow={setAddAddress} />
      <div
        onClick={() => setAddAddress(false)}
        className="click-to-add-address"
      >
        Back to checkout
      </div>
    </>
  ) : (
    <div>
      <div className="extras-detail-main row">
        <div className="col-sm-6">
          <h5>{t("Cart.Information")}</h5>
          <input
            defaultValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            className="form-control checkout-input"
          />
          <input
            defaultValue={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            className="form-control checkout-input"
          />
          <input
            defaultValue={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number"
            className="form-control"
          />
          <hr style={{ margin: "10px" }} />
          <h5>Promo Code</h5>
          <div className="flex-center">
            <input
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter the code..."
              className="form-control checkout-input promo-input"
            />
            <button
              onClick={() => dispatch(applyPromo())}
              className="submot-promo-button"
            >
              {promoLoading ? (
                <BiLoaderAlt className="login-loader" />
              ) : !applied ? (
                "Apply"
              ) : (
                <BiCheck />
              )}
            </button>
          </div>
          <span className="error-message">{err}</span>
          <hr style={{ margin: "10px" }} />
          {deliverWay === 1 ? (
            <h5>{t("Cart.ChooseAddress")}</h5>
          ) : (
            <h5>{t("Cart.MealCreatorAddress")}</h5>
          )}

          {deliverWay === 1 ? (
            addresses?.length === 0 ? (
              <div
                className="click-to-add-address"
                onClick={() => setAddAddress(true)}
              >
                {t("Cart.AddNewAddress")}
              </div>
            ) : (
              <div>
                {addresses?.map((address, index) => {
                  return (
                    <div className="flex-between" key={index}>
                      <div className="checkbox-wrapper">
                        <input
                          onClick={(e) => setAddress(e.target.value)}
                          id={`address${index}`}
                          value={address.id}
                          type="radio"
                          name="address-radio"
                          className="form-check-input"
                        />
                        <label
                          htmlFor={`address${index}`}
                          className="form-check-label"
                        >
                          {address.street}
                        </label>
                      </div>
                    </div>
                  );
                })}
                <div
                  onClick={() => setAddAddress(true)}
                  className="click-to-add-address"
                >
                  {t("AddNewAddress")}
                </div>
              </div>
            )
          ) : (
            <p>{creatorAddress}</p>
          )}
        </div>
        <div className="col-sm-6">
          <div
            className="extras-detail-options"
            style={{
              display: "block",
            }}
          >
            <hr />
            {price < user?.balance && (
              <div className="flex-between">
                <div className="checkbox-wrapper">
                  <input
                    onChange={(e) => handleUseWalletChange(e)}
                    id={`wallet`}
                    value="1"
                    type="checkbox"
                    className="form-check-input"
                  />
                  <label htmlFor={`wallet`} className="form-check-label">
                    {t("Cart.UseWallet")}
                  </label>
                </div>
              </div>
            )}
            <select
              onChange={(e) => setZone(e.target.value)}
              style={{ height: "40px" }}
            >
              <option>Choose Zone</option>
              {zones?.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            <hr style={{ margin: "10px" }} />
            <OrderCheck>
              <div>
                {t("Cart.Subtotal")} <span>{theSub} CA$</span>
                {discounted ? (
                  <span className="price-before">{subTotal}</span>
                ) : null}
              </div>
              <div>
                Delivery Fees: <span>{currentZone?.fees} CA$</span>
              </div>
              <div>
                {t("Cart.Vat")} (13%){" "}
                <span>{(theSub * 0.07).toFixed(2)} CA$</span>
              </div>
              <OrderCheckTotal>
                {t("Cart.Total")} <span>{price} CA$</span>
                {discounted ? (
                  <span className="price-before">{totalBefore}</span>
                ) : null}
              </OrderCheckTotal>
              {/* <div>There are no items in your basket.</div> */}
            </OrderCheck>
          </div>
          <button onClick={() => handleSubmit()} className="auth-button">
            {loading ? (
              <BiLoaderAlt className="login-loader" />
            ) : (
              t("Cart.Checkout")
            )}
          </button>
        </div>
        <div className="extras-detail-selected" />
      </div>
    </div>
  );
}

export default Checkout;
