import React from "react";
import { useTranslation } from "react-i18next";
// import { useLocation, useHistory } from "react-router-dom";
import AutoComplete from "../components/SearchInput";

const SearchMainSearchForm = ({ setSearchKey }) => {
  const { t } = useTranslation();
  // const { pathname } = useLocation();
  // const history = useHistory();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (searchKey.length > 0) {
  //     if (pathname.includes("meals") && searchKey.length > 0) {
  //       history.push(`/meals/search/${searchKey}`);
  //     } else {
  //       history.push(`/meal-creators/search/${searchKey}`);
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchKey.length > 0) {
  //       if (pathname.includes("meals") && searchKey.length > 0) {
  //         history.push(`/meals/search/${searchKey}`);
  //       } else {
  //         history.push(`/meal-creators/search/${searchKey}`);
  //       }
  //     }
  //     // if (searchKey.length === 0) {
  //     //   if (pathname.includes("meals") && searchKey.length > 0) {
  //     //     history.push(`/meals`);
  //     //   } else {
  //     //     history.push(`/meal-creators`);
  //     //   }
  //     // }
  //   }, [500]);
  //   return () => clearTimeout(timer);
  // }, [searchKey, searchKey.length]);

  // console.log("SEARCH KET", searchKey.length);
  return (
    <div className="main-search">
      <div className="restaurant-search-element-container row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="field-holder">
            {" "}
            <span
              style={{ top: "19px" }}
              className="restaurant-element-search-btn"
            >
              <i className="icon-search5" />{" "}
            </span>
            <AutoComplete
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder={`XChef ${t("Shared.Search")}`}
              defaultValue={""}
              type="text"
              setSearchKey={setSearchKey}
            />
            {/* <div className="flex-center">
                <button className="my-search-butt">{t("Shared.Search")}</button>
              </div> */}
          </div>
        </div>
        {}
      </div>
    </div>
  );
};

export default SearchMainSearchForm;
