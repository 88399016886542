import { combineReducers } from "redux";
import authReducer from "./authReducer";
import blogsReducer from "./blogsReducer";
import cartReducer from "./cartReducer";
import creatorsReducer from "./creatorsReducer";
import homeReducer from "./homeReducer";
import mealsReducer from "./mealsReducer";
import ordersReducer from "./orderReducer";
import userReducer from "./userReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const cartConfig = {
  key: "cart-config",
  storage,
  whitelist: ["localCart"],
  blacklist: [],
};

export default combineReducers({
  home: homeReducer,
  creators: creatorsReducer,
  meals: mealsReducer,
  auth: authReducer,
  cart: persistReducer(cartConfig, cartReducer),
  user: userReducer,
  orders: ordersReducer,
  blogs: blogsReducer,
});
