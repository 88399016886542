import React from 'react';
import SearchTopSection from "../../components_search/SearchTopSection";
import SearchResultSection from "../../components_search/SearchResultSection";

export const SearchPage = () => {
  const [searchKey, setSearchKey] = React.useState('');
    return (
      <div>
        <SearchTopSection setSearchKey={setSearchKey}></SearchTopSection>
        <SearchResultSection searchKey={searchKey}></SearchResultSection>
      </div>
    );
};
  