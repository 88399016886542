import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { BASE_URL } from '../../redux/actions/types';
import AppSpinner from '../../components/Spinner/Spinner';
import Banner from '../../components_home/Banner';

const Terms = () => {
    const dispatch = useDispatch();
    const [content, setContent] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const getTerms = () => dispatch => {
        setLoading(true)
        axios.get(`${BASE_URL}/pages/1`)
            .then(res => {
                setContent(res?.data?.page?.content)
                console.log(res?.data?.page?.content)
                setLoading(false)
                dispatch({type: "RR"})
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        dispatch(getTerms())
    }, [])
    return (
        <>
        <Banner offers />
        <div style={{marginTop: '80px'}} className='container'>
            <div className='terms-header'>
                <h1 className='terms-header-text'>
                    Terms & Conditions
                </h1>
                {/* <div className='terms-header-image-wrapper'>
                    <img src='/terms-card.png' alt='img' className='term-card-image' />
                </div> */}
            </div>
            <div style={{ height: '50px' }}>

            </div>
            {
                loading ? <AppSpinner /> :
                    <div className='flex-center p-4'>
                        <div className='app-container mb-5'>
                            {
                                content?.length > 3 ?
                                    parse(content) : null
                            }
                        </div>
                    </div>
            }
        </div>
        </>
    )
}

export default Terms;