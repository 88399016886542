import React from 'react'
import SingleReview from './SingleReview'

function Reviews() {
    return (
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div style={{ width: '100%' }} className="user-dashboard loader-holder">
                <div className="user-holder">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="element-title has-border reviews-header right-filters-row">
                                <h5>
                                    <span>Reviews Given</span>
                                    <span className="element-slogan">(2)</span>
                                </h5>
                                <div className="right-filters row pull-right">
                                    <div className="col-lg-6 col-md-6 col-xs-6">
                                        <div className="sort-by">
                                            <ul className="reviews-sortby">
                                                <li>
                                                    <small>Sort by:</small>
                                                    <span><strong className="active-sort">Newest Reviews </strong></span>
                                                    <div className="reviews-sort-dropdown">
                                                        <form>
                                                            <div className="input-reviews">
                                                                <div className="radio-field">
                                                                    <input name="review" id="check-1" type="radio" value="newest" checked="checked" />
                                                                    <label htmlFor="check-1">Newest Reviews</label>
                                                                </div>
                                                                <div className="radio-field">
                                                                    <input name="review" id="check-2" type="radio" value="highest" />
                                                                    <label htmlFor="check-2">Highest Rating</label>
                                                                </div>
                                                                <div className="radio-field">
                                                                    <input name="review" id="check-3" type="radio" value="lowest" />
                                                                    <label htmlFor="check-3">Lowest Rating</label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-6 pull-right">
                                        <div className="input-field">
                                            <i className="icon-angle-down"></i>
                                            <input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="user-reviews-list">
                                <div className="review-listing">
                                    <ul>
                                        <SingleReview />
                                        <SingleReview />
                                        <SingleReview />
                                        <SingleReview />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ul className="pagination">
                                <li className="active"><a>1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><span className="page-numbers dots">…</span></li>
                                <li><a href="#">24</a></li>
                                <li><a href="#">Next </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews