import React from "react";
import BannerTitle from "./BannerTitle";
import BannerSearch from "./BannerSearch";
// import BannerCategoriesList from "./BannerCategoriesList";

const Banner = ({ offers }) => {
  return (
    <div
      className="page-section nopadding cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "200px",
        paddingBottom: "0px",
        marginBottom: "-60px",
        background:
          "url(/assets/extra-images/home-top-1.jpg) no-repeat center / cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <BannerTitle></BannerTitle>
              {offers ? null : <BannerSearch></BannerSearch>}
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{
                  height: "50px",
                }}
              ></div>
              {/* <BannerCategoriesList></BannerCategoriesList> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
