import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export function myToast(text) {
    toast(<span className='notification-text'>{text}</span>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        progressStyle: { color: "#3ca556", background: "#3ca556" },
    });
}

export function myError(text) {
    toast(<span className='notification-text'>{text}</span>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        progressStyle: { color: "red", background: "red" },
    });
}