import styled from "styled-components";

export const YourOrder = styled.div`
  background-color: transparent;
  flex: 2.2;
`;

export const YourOrderCart = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 5px -2px #eee;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const YourOrderCartContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    font-size: 19px;
  }

  h3 {
    margin: 0;
    font-weight: bold;
  }
`;

export const YourOrderCartHeader = styled.div`
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

export const DivDiscountCodeMessage = styled.div`
  background-color: #ebedf3;
  padding: 10px;
  margin-bottom: 10px;
`;

export const PickupAndDeliveryForm = styled.div`
  display: flex;

  & > div {
    margin-right: 15px;
  }

  & > div input {
    margin-right: 5px;
  }

  div span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    color: #999ba3;
  }
`;

export const SingleOrder = styled.div`
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 13px;

  & div:first-child {
    margin-left: 13px;

    span {
      float: right;
    }
  }

  ul.extra {
    list-style: none;
    padding: 0 0 0 10px;

    li::before {
      content: "*";
      color: red !important;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      /* position: relative;
      top: 3px; */
    }
  }
`;

export const OrderCheck = styled.div`
  border-bottom: 1px solid #ebedf3;

  & > div {
    margin-bottom: 10px;
  }
  span {
    float: right;
  }
`;

export const OrderCheckTotal = styled.div`
  padding: 10px;
  background-color: #ebedf3;

  span {
    font-weight: bold;
  }
`;

export const OrderPaymentMethods = styled.div`
  display: flex;
  justify-content: flex-start;

  & > div {
    display: flex;
    justify-content: center;
    padding: 10px;

    svg {
      font-size: 30px;
      color: #bdbec2;
      margin-right: 10px;
    }

    span {
      font-weight: bold;
    }
  }
`;
