import React from "react";

class MealCreatorCenterSectionTabsHolderTabs extends React.Component {
  render() {
    return (
      <ul className="stickynav-tabs nav nav-tabs">
        <li className="active">
          <a data-toggle="tab" href="#home">
            <i className="icon- icon-room_service" />
            Menu
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#menu1">
            <i className="icon- icon-textsms" />
            Reviews (1)
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#menu2">
            <i className="icon- icon-info3" />
            Restaurant Info
          </a>
        </li>
      </ul>
    );
  }
}

export default MealCreatorCenterSectionTabsHolderTabs;
