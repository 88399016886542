import React from "react";
import FeaturedMealCreatorSectionTitle from "./FeaturedMealCreatorSectionTitle";
import FeaturedMealCreatorSectionList from "./FeaturedMealCreatorSectionList";
import { useDispatch, useSelector } from "react-redux";
import AppSpinner from "../components/Spinner/Spinner";
import { getHomePage, setHomeLoading } from "../redux/actions/homeActions";

const FeaturedMealCreatorSection = ({creators}) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.home.loading)
  React.useEffect(() => {
    dispatch(setHomeLoading())
    dispatch(getHomePage())
  }, [])
  return (
    <div
      className="page-section nopadding cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "90px",
        paddingBottom: "10px",
        marginBottom: "0px",
        background: "#ffffff"
      }}
    >
      {
        loading? <AppSpinner />: 
          <div className="container">
            <div className="row">
              <FeaturedMealCreatorSectionTitle></FeaturedMealCreatorSectionTitle>
              <FeaturedMealCreatorSectionList creators={creators}></FeaturedMealCreatorSectionList>
            </div>
          </div>
      }
    </div>
  );
}

export default FeaturedMealCreatorSection;
