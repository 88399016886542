import React from "react";
import MainHeader from "./MainHeader";

class Header extends React.Component {
  render() {
    return (
      <header
        id="header"
        className="sticky-header header-full-width transparent-header default"
      >
        <MainHeader></MainHeader>
      </header>
    );
  }
}

export default Header;
