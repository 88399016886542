import axios from "axios";
import * as types from "./types";

export const getCreators = (currentPage, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .get(`${types.BASE_URL}/meal-creators?page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_CREATORS,
        payload: res.data,
      });
    })
    .finally(() => setLoading(false));
};

export const getCreatorById = (id) => (dispatch) => {
  axios.get(`${types.BASE_URL}/meal-creator-meals/${id}`).then((res) => {
    dispatch({
      type: types.GET_CREATOR_BY_ID,
      payload: res.data,
    });
  });
};

export const creatorsSearch =
  (searchKey, currentPage, limit, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .get(
        `${types.BASE_URL}/search?search_type=meal_creator&search_text=${searchKey}&paginate=${limit}&page=${currentPage}`
      )
      .then((res) => {
        dispatch({
          type: types.GET_CREATORS,
          payload: res.data,
        });
      })
      .finally(() => setLoading(false));
  };

export const getFilters = () => (dispatch) => {
  axios.get(`${types.BASE_URL}/filter-attributes`).then((res) => {
    dispatch({
      type: types.GET_FILTERS,
      payload: res.data,
    });
  });
};

export const setCreatorsLoading = () => (dispatch) => {
  dispatch({
    type: "SS",
  });
};
