import React from "react";

class MobileAppSectionForm extends React.Component {
  render() {
    return (
      <form>
        {/* <div className="field-holder">
          <input
            className="field-input"
            type="email"
            placeholder="Your Email"
          />
          <label className="field-label-btn">
            <input
              className="field-btn"
              type="button"
              onClick={() => {
                
              }}
              defaultValue="Send Link"
            />
          </label>
        </div> */}
      </form>
    );
  }
}

export default MobileAppSectionForm;
