import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getMeals,
  // mealsLoading,
  mealsSearch,
} from "../redux/actions/mealsActions";
import SearchResultSectionLeftMenu from "./SearchResultSectionLeftMenu";
import SearchResultSectionCenterList from "./SearchResultSectionListMeals";
import SearchResultSectionRightMenu from "./SearchResultSectionRightMenu";

const SearchResultSection = ({ localCart, setLocalCart, searchKey }) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = React.useState("  ");
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState("name");
  const { pathname } = useLocation();
  console.log("PATH", pathname.includes("search"));
  const isSearch = searchKey?.length > 0;
  const [currentPage, setCurrentPage] = React.useState(1);
  console.log("PATHNAME", pathname);
  // console.log("SORTT", sort)
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isSearch) {
        // dispatch(mealsLoading());
        dispatch(mealsSearch(searchKey, currentPage, 8, setLoading));
      } else {
        setTimeout(() => {
          // dispatch(mealsLoading());
          dispatch(getMeals(filterData, sort, currentPage, 8, setLoading));
        }, [100]);
      }
    }, [500]);
    return () => clearTimeout(timer);
  }, [filterData, sort, pathname, currentPage, searchKey]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchKey]);
  return (
    <div
      className="page-section nopadding cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "60px",
        paddingBottom: "10px",
        marginBottom: "0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <SearchResultSectionLeftMenu
                setData={setFilterData}
              ></SearchResultSectionLeftMenu>
              <SearchResultSectionCenterList
                searchKey={searchKey}
                localCart={localCart}
                setLocalCart={setLocalCart}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
              ></SearchResultSectionCenterList>
              <SearchResultSectionRightMenu
                setLocalCart={setLocalCart}
                localCart={localCart}
                sort={sort}
                setSort={setSort}
              ></SearchResultSectionRightMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultSection;
