import React from "react";
import MealCreatorCenterSectionTabsHolderTab1MenuListCategory1 from "./MealCreatorCenterSectionTabsHolderTab1MenuListCategory1";
import MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList from "./MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList";

const MealCreatorCenterSectionTabsHolderTab1MenuList = ({localCart, setLocalCart, categories}) => {
  console.log("CATEG")
  return (
    <div id="menu-item-list-6272" className="menu-itam-list">
      {
        categories?.map((cat, index) => {
          return(
            <div key={index}>
              <MealCreatorCenterSectionTabsHolderTab1MenuListCategory1 category={cat}></MealCreatorCenterSectionTabsHolderTab1MenuListCategory1>
              {
                cat?.meals?.map((meal, index) => {
                  return(
                    <div key={index}>
                      <MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList localCart={localCart} setLocalCart={setLocalCart} meal={meal}></MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  );
}

export default MealCreatorCenterSectionTabsHolderTab1MenuList;
