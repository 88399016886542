import * as types from '../actions/types'

const initialState = {
    meals: [],
    mealCreators: [],
    loading: false
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HOME:
            return {
                ...state,
                meals: action.payload?.mostPopularMeals,
                mealCreators: action.payload?.featuredMealCreators,
                loading: false
            };

        case types.HOME_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state
    }
}

export default homeReducer;