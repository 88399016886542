import React from "react";
import Banner from "../../components_home/Banner";
import MostPopularSection from "../../components_home/MostPopularSection";
import FeaturedMealCreatorSection from "../../components_home/FeaturedMealCreatorSection";
import MobileAppSection from "../../components_home/MobileAppSection";
import { useDispatch } from "react-redux";
import { getHomePage, setHomeLoading } from "../../redux/actions/homeActions";
import { useSelector } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { creatorsSearch } from "../../redux/actions/creatorsActions";
import { getOffers, mealsSearch } from "../../redux/actions/mealsActions";
import AppSpinner from "../../components/Spinner/Spinner";

export const HomePage = () => {
  const [localCart, setLocalCart] = React.useState(
    localStorage.getItem("localCart")
      ? JSON.parse(localStorage.getItem("localCart"))
      : []
  );
  const { pathname } = useLocation();
  const isSearch = pathname.includes("search");
  const { searchKey } = useParams();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isSearch) {
      dispatch(creatorsSearch(searchKey, 1, 6, () => {}));
      dispatch(mealsSearch(searchKey, 1, 6, () => {}));
      dispatch(getOffers(setLoading));
    } else {
      dispatch(setHomeLoading());
      dispatch(getHomePage());
      dispatch(getOffers(setLoading));
    }
  }, [pathname]);
  const creators = isSearch
    ? useSelector((state) => state.creators?.creators?.search_result?.data)
    : useSelector((state) => state.home.mealCreators);
  const meals = isSearch
    ? useSelector((state) => state.meals?.meals?.search_result?.data)
    : useSelector((state) => state.home.meals);
  const offers = useSelector((state) => state?.meals?.offers?.offers);

  return (
    <>
      <Banner></Banner>
      <FeaturedMealCreatorSection
        creators={creators}
      ></FeaturedMealCreatorSection>
      <MostPopularSection
        localCart={localCart}
        setLocalCart={setLocalCart}
        meals={meals}
      ></MostPopularSection>
      {loading ? (
        <div className="flex-center">
          <AppSpinner />
        </div>
      ) : (
        <MostPopularSection
          localCart={localCart}
          setLocalCart={setLocalCart}
          offers
          meals={offers}
        />
      )}
      <MobileAppSection></MobileAppSection>
    </>
  );
};
