import axios from "axios";
import * as types from "./types";

export const getBlogs = (setLoading) => (dispatch) => {
  setLoading(true);
  axios.get(`${types.BASE_URL}/blogs`).then((res) => {
    setLoading(false);
    dispatch({
      type: types.GET_BLOGS,
      payload: res.data,
    });
  });
};

export const getBlogById = (id, setLoading) => (dispatch) => {
  setLoading(true);
  axios.get(`${types.BASE_URL}/blog/${id}`).then((res) => {
    setLoading(false);
    dispatch({
      type: types.GET_SINGLE_BLOG,
      payload: res.data,
    });
  });
};

export const creatorsSearch = (searchKey, currentPage, limit) => (dispatch) => {
  axios
    .get(
      `${types.BASE_URL}/search?search_type=meal_creator&search_text=${searchKey}&paginate=${limit}&page=${currentPage}`
    )
    .then((res) => {
      dispatch({
        type: types.GET_CREATORS,
        payload: res.data,
      });
    });
};

export const getFilters = () => (dispatch) => {
  axios.get(`${types.BASE_URL}/filter-attributes`).then((res) => {
    dispatch({
      type: types.GET_FILTERS,
      payload: res.data,
    });
  });
};

export const setCreatorsLoading = () => (dispatch) => {
  dispatch({
    type: types.CREATORS_LOADING,
  });
};
