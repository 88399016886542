import React from "react";
import HeaderAuth from "./HeaderAuth";
import HeaderNav from "./HeaderNav";

class HeaderRight extends React.Component {
  render() {
    return (
      <div
        style={{ marginTop: "5px" }}
        className="col-lg-9 col-md-9 col-sm-12 col-xs-12"
      >
        <HeaderAuth></HeaderAuth>
        <HeaderNav></HeaderNav>
      </div>
    );
  }
}

export default HeaderRight;
