import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getUser, removeAddress } from "../../../redux/actions/userActions";
import { BiLoaderAlt } from "react-icons/bi";
import ReusableModal from "../../ReusableModal";
import { useTranslation } from "react-i18next";
import EditAddress from "./EditAddress";

function AddressCard({ address }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [editShow, setEditShow] = React.useState(false);
  const dispatch = useDispatch();
  const callBack = () => {
    dispatch(getUser());
  };
  const modalBody = (
    <div>
      <h5 className="text-center">
        {t("Confirmation.Are you sure that you want to remove the address?")}
      </h5>
      <div className="flex-between mt-4">
        <button onClick={() => setShow(false)} className="clear-no-button">
          NO
        </button>
        <button
          onClick={() =>
            dispatch(removeAddress(address?.id, callBack, setLoading, setShow))
          }
          className="clear-yes-button"
        >
          {loading ? <BiLoaderAlt className="login-loader" /> : "YES"}
        </button>
      </div>
    </div>
  );
  return (
    <div className="flex-center">
      <ReusableModal
        show={editShow}
        setShow={setEditShow}
        title={t("Profile.UpdateAddress")}
        body={<EditAddress setShow={setEditShow} address={address} />}
      />
      <div className="col-md-6 address-card">
        <ReusableModal
          show={show}
          setShow={setShow}
          title="Delete Address"
          body={modalBody}
        />
        <div
          className="flex-between address-header-wrapper"
          style={{ margin: "0" }}
        >
          {/* <MdDelete
            onClick={() => setShow(true)}
            className="delete-address-icon"
          />
          <MdEdit
            className="delete-address-icon"
            onClick={() => setEditShow(true)}
          /> */}
        </div>
        <div className="row">
          <div className="flex-between">
            <h6 className="address-title" style={{ margin: "0" }}>
              {address?.street}
            </h6>
            <div>
              <MdEdit
                className="delete-address-icon"
                onClick={() => setEditShow(true)}
                style={{ marginRight: "15px" }}
              />
              <MdDelete
                onClick={() => setShow(true)}
                className="delete-address-icon"
              />
            </div>
          </div>
          <div className=" address-prop">{t("Profile.Area")}</div>
          <input value={address?.area} disabled />
          <div className="address-prop">{t("Profile.Street")}</div>
          <input value={address?.street} disabled />
          {address?.building_type === 1 ? (
            <>
              <div className="address-prop">{t("Profile.VillaNumber")}</div>
              <input value={address?.villa_number} disabled />
            </>
          ) : (
            <>
              <div className="address-prop">{t("Profile.BuildingNumber")}</div>
              <input value={address?.building_number} disabled />
              <div className="address-prop">{t("Profile.Floor")}</div>
              <input value={address?.floor_number} disabled />
              <div className="address-prop">{t("Profile.Apartment")}</div>
              <input value={address?.apartment_number} disabled />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddressCard;
