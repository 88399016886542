import React from "react";
import MobileAppSectionInfo from "./MobileAppSectionInfo";

class MobileAppSection extends React.Component {
  render() {
    return (
      <div
        className="page-section   nopadding cs-nomargin"
        style={{
          marginTop: "0px",
          paddingTop: "52px",
          paddingBottom: "0px",
          marginBottom: "0px",
          borderTop: "1px #dbdbdb solid",
          borderBottom: "1px #dbdbdb solid",
          background: "#f0f0f0"
        }}
      >
        <div className="container ">
          <div className="row">
            <MobileAppSectionInfo></MobileAppSectionInfo>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileAppSection;
