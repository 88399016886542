import React from 'react';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { myToast } from './Toast';
import { BiLoaderAlt } from 'react-icons/bi';
import {useParams} from 'react-router-dom';
import {BASE_URL} from '../redux/actions/types'
import { useTranslation } from 'react-i18next';

const Review = ({ callBack, setShow }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [rate, setRate] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [err, setErr] = React.useState([]);
    const {creatorId} = useParams()



    const sendRating = () => (dispatch) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('rate', rate)
        formData.append('comment', comment)
        formData.append('meal_creator_id', creatorId)
        axios.post(`${BASE_URL}/user/meal-creator-add-or-update-review`, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setErr([])
                setLoading(false)
                setShow(false)
                callBack()
                myToast(t('Toast.Your rate has been sent successfully'))
                dispatch({
                    type:"R"
                })
            })
            .catch(err => {
                console.log('err', err.response?.data?.errors)
                setErr(err.response?.data?.errors)
                setLoading(false)
            })
    }

    const handleSubmit = () => {
        dispatch(sendRating())
    }
    return (
        <div>
            <div className='flex-center' style={ { direction: 'rtl', width: '100%' } }>
                <div className='stars'>
                    <input onClick={(e) => setRate(e.target.value)} type='radio' id='five' name='rate' value='5' />
                    <label htmlFor='five'></label>
                    <input onClick={(e) => setRate(e.target.value)} type='radio' id='four' name='rate' value='4' />
                    <label htmlFor='four'></label>
                    <input onClick={(e) => setRate(e.target.value)} type='radio' id='three' name='rate' value='3' />
                    <label htmlFor='three'></label>
                    <input onClick={(e) => setRate(e.target.value)} type='radio' id='2' name='rate' value='2' />
                    <label htmlFor='2'></label>
                    <input onClick={(e) => setRate(e.target.value)} type='radio' id='one' name='rate' value='1' />
                    <label htmlFor='one'></label>
                </div>
            </div>
            <span style={{ display: 'block' }} className='error-message ml-4 mb-2'>{err?.rate ? err?.rate : null}</span>
            <label className='text-left mt-2'>Add Comment</label>
            <textarea onChange={(e) => setComment(e.target.value)} placeholder='Type your comment....' rows={3} className='rating-text-area'></textarea>
            <div className='flex-center mt-8'>
                <button onClick={handleSubmit} className='auth-button' id='close'>{loading ? <BiLoaderAlt className='login-loader' /> : 'Submit'}</button>
            </div>
        </div>
    )
}

export default Review;