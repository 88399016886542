import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  clearCart,
  getCart,
} from "../redux/actions/cartActions";
import { IoIosClose } from "react-icons/io";
import ReusableModal from "./ReusableModal";
import { myError } from "./Toast";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: "450px",
    maxWidth: "98%",
    borderRadius: "16px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "1200",
  },
};

function AppModal({ meal, show, setShow }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [clearLoading, setClearLoading] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [error, setError] = React.useState({});
  const dispatch = useDispatch();
  const callBack = () => {
    dispatch(getCart());
    setShow(false);
  };
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setShow(false);
  }

  const [extras, setExtras] = React.useState([]);
  console.log("EXTRAS", extras);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      setExtras(extras.concat([id]));
    } else {
      setExtras(extras.filter((row) => row !== id));
    }
  };
  const items = useSelector((state) => state.cart.cart?.cart);
  console.log("ITEMS", items);
  const [size, setSize] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const currentSize = meal?.sizes?.find(
    (si) => parseInt(si.id) === parseInt(size)
  );
  const sizePrice = currentSize?.price;
  const selectedExtras = meal?.options?.filter((f) =>
    extras.some((item) => item === f.id)
  );
  const selectedExtrasPrices = selectedExtras?.map((extra) => extra.price);
  const extrasTotalPrice = selectedExtrasPrices?.reduce((a, b) => a + b, 0);

  const totalPrice = (sizePrice + extrasTotalPrice) * quantity;

  const itemData = {
    options: selectedExtras,
    sizeId: currentSize?.id,
    mealId: meal?.id,
    quantity,
  };
  const firstMealCreatorId = items ? items[0]?.meal?.meal_creator_id : null;

  console.log(`First Creator ${firstMealCreatorId}`);

  const addAnotherCreator = () => {
    dispatch(clearCart(() => setShowConfirm(false), setClearLoading));
  };

  const body = (
    <div>
      <h5 className="text-center">
        {t(
          "Confirmation.Are you sure that you want to remove the items of the another restaurant?"
        )}
      </h5>
      <div className="flex-between mt-4">
        <button
          onClick={() => setShowConfirm(false)}
          className="clear-no-button"
        >
          NO
        </button>
        <button
          onClick={() => addAnotherCreator()}
          className="clear-yes-button"
        >
          {clearLoading ? <BiLoaderAlt className="login-loader" /> : "YES"}
        </button>
      </div>
    </div>
  );

  console.log("EXTRAS PRICES", extrasTotalPrice);
  console.log("SIZE", currentSize);
  const handleAddToCart = () => {
    if (size === "") {
      myError(t("Toast.Choose size first"));
    } else {
      if (
        firstMealCreatorId !== meal?.meal_creator_id &&
        items?.length !== 0 &&
        firstMealCreatorId &&
        meal?.meal_creator_id
      ) {
        setShowConfirm(true);
        // console.log("KKKKKKKKKKK", meal?.meal_creator_id)
      } else {
        dispatch(addItemToCart(itemData, callBack, setError, setLoading));
      }
    }
  };
  console.log(error);

  return (
    <>
      <div>
        <Modal
          isOpen={show}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ReusableModal
            show={showConfirm}
            setShow={setShowConfirm}
            title="Confirmation"
            body={body}
          />
          <div className="flex-between">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
              {t("Shared.MealOptions")}
            </h2>
            <IoIosClose
              onClick={() => setShow(false)}
              className="close-modal-symbol"
            />
          </div>

          <hr style={{ marginBottom: "-10px" }} />
          <div className="modal-body">
            <div className="menu-selection-container">
              <div className="extras-detail-main">
                <h3>{t("Shared.Ingredients")}</h3>
                <div
                  className="extras-detail-options"
                  style={{
                    display: "block",
                  }}
                >
                  <div>{meal?.ingredients}</div>
                </div>
                <div className="extras-detail-selected" />
              </div>
              <div className="extras-detail-main">
                <h3 style={{ marginTop: "-10px" }}>{t("Shared.Extra")}</h3>
                <div
                  className="extras-detail-options"
                  style={{
                    display: "block",
                  }}
                >
                  {meal?.options?.map((opt, index) => {
                    return (
                      <div className="flex-between" key={index}>
                        <div className="checkbox-wrapper">
                          <input
                            value={opt.id}
                            onChange={(e) => handleChange(e, opt.id)}
                            id={`opt${index}`}
                            type="checkbox"
                            className="form-check-input"
                          />
                          <label
                            htmlFor={`opt${index}`}
                            className="form-check-label"
                          >
                            {opt.name}
                          </label>
                        </div>
                        <p className="extra-price">{opt.price} $</p>
                      </div>
                    );
                  })}
                </div>
                <div className="extras-detail-selected" />
              </div>
              <div className="extras-detail-main">
                <h3 style={{ marginTop: "-10px" }}>{t("Shared.Size")}</h3>
                <div
                  className="extras-detail-options"
                  style={{
                    display: "block",
                  }}
                >
                  {meal?.sizes?.map((size, index) => {
                    return (
                      <div className="flex-between" key={index}>
                        <div className="checkbox-wrapper">
                          <input
                            onClick={(e) => setSize(e.target.value)}
                            id={`size${index}`}
                            value={size.id}
                            type="radio"
                            name="size-radio"
                            className="form-check-input"
                          />
                          <label
                            htmlFor={`size${index}`}
                            className="form-check-label"
                          >
                            {size.name}
                          </label>
                        </div>
                        <p className="extra-price">{size.price} $</p>
                      </div>
                    );
                  })}
                </div>
                <div className="extras-detail-selected" />
              </div>
              <div className="flex-between">
                <h3 style={{ marginTop: "-10px" }}>{t("Shared.Quantity")}</h3>
                <div className="flex-between counter-container">
                  <span
                    onClick={() =>
                      quantity === 1 ? null : setQuantity(quantity - 1)
                    }
                    className="counter-plus"
                  >
                    <span style={{ marginBottom: "2px" }}>-</span>
                  </span>
                  <span>{quantity}</span>
                  <span
                    onClick={() => setQuantity(quantity + 1)}
                    className="counter-plus"
                  >
                    <span style={{ marginBottom: "2px" }}>+</span>
                  </span>
                </div>
              </div>
              <div className="flex-center">
                <span>
                  {t("Shared.TotalPrice")}: {isNaN(totalPrice) ? 0 : totalPrice}{" "}
                  CA$
                </span>
              </div>
              <div className="extras-btns-holder">
                <button
                  className="add-to-cart-butt"
                  onClick={() => handleAddToCart()}
                >
                  {loading ? (
                    <BiLoaderAlt className="login-loader" />
                  ) : (
                    t("Shared.AddToCart")
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AppModal;
