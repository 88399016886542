import React from "react";
import SearchResultSectionCenterList from "./SearchResultSectionCenterList";

const SearchResultSection = ({ searchKey }) => {
  return (
    <div
      className="page-section nopadding meal-creators-page-wrapper cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "60px",
        paddingBottom: "10px",
        marginBottom: "0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <SearchResultSectionCenterList
                searchKey={searchKey}
              ></SearchResultSectionCenterList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultSection;
