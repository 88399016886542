import axios from "axios";
import { myToast } from "../../components/Toast";
import * as types from "./types";

export const getUser = () => (dispatch) => {
  axios
    .get(`${types.BASE_URL}/user/user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_USER,
        payload: res.data,
      });
      console.log(res.data?.user);
      localStorage.setItem("firstName", res.data?.user?.first_name);
      localStorage.setItem("secondName", res.data?.user?.last_name);
      localStorage.setItem(
        "userName",
        res.data?.user?.first_name + " " + res.data?.user?.last_name
      );
      localStorage.setItem("userEmail", res.data?.user?.email);
      localStorage.setItem("userPhone", res.data?.user?.phone);
    });
};

export const addItemToCart =
  (itemData, callBack, setError, setLoading) => (dispatch) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("meal_size_id", itemData.sizeId);
    formData.append("meal_id", itemData.mealId);
    formData.append("quantity", itemData.quantity);
    itemData?.options?.map((option) =>
      formData.append("meal_option_id[]", option.id)
    );
    axios
      .post(`${types.BASE_URL}/user/update-or-add-to-cart`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        dispatch({
          type: "S",
        });
        setLoading(false);
        callBack();
        myToast("Item has been added successfully");
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

export const clearCart = (callBack, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(
      `${types.BASE_URL}/user/empty-cart`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: "S",
      });
      setLoading(false);
      callBack();
      myToast("Cart has been cleared successfully");
    })
    .catch(() => {
      setLoading(false);
    });
};

export const removeCartItem = (callBack, setLoading, itemId) => (dispatch) => {
  setLoading(true);
  axios
    .post(
      `${types.BASE_URL}/user/remove-cart-item`,
      { cart_item_id: itemId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: "S",
      });
      setLoading(false);
      callBack();
      myToast("Item has been removed successfully");
    })
    .catch(() => {
      setLoading(false);
    });
};

export const editItemQuantity =
  (callBack, setLoading, itemId, quantity) => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${types.BASE_URL}/user/update-item-quantity`,
        { cart_item_id: itemId, quantity },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: "S",
        });
        setLoading(false);
        callBack();
        myToast("Item quantity has been edited successfully");
      })
      .catch(() => {
        setLoading(false);
      });
  };

export const getLikes = (setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .get(`${types.BASE_URL}/user/likes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_LIKES,
        payload: res.data,
      });
      setLoading(false);
    })
    .catch(() => setLoading(false));
};

export const likeMeal = (id, isFav, setIsFav, callBack) => (dispatch) => {
  axios
    .post(
      `${types.BASE_URL}/user/like-meal`,
      { meal_id: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setIsFav(!isFav);
      dispatch({ type: "ss" });
      myToast(res?.data?.message);
      callBack();
    })
    .catch((err) => console.log(err.response));
};

export const removeAddress =
  (id, callBack, setLoading, setShow) => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${types.BASE_URL}/user/remove-address`,
        { address_id: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        dispatch({ type: "ss" });
        myToast("Address has been deleted");
        callBack();
        setShow(false);
      })
      .catch(() => setLoading(false));
  };

export const getZones = () => (disptch) => {
  axios.get(`${types.BASE_URL}/zones`).then((res) => {
    disptch({
      type: types.GET_ZONES,
      payload: res.data,
    });
  });
};
