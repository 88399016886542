import React from "react";
import {BiCategory} from 'react-icons/bi'

const SearchResultSectionLeftMenuFilterOptionsCategories = ({selectedCategories, setSelectedCategories, categories}) => {
  const [open, setOpen] = React.useState(false);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      setSelectedCategories(selectedCategories.concat([id]))
    } else {
      setSelectedCategories(selectedCategories.filter(row => row !== id))
    }
  }
  return (
    <div className="filter-holder panel-default">
      <div onClick={() => setOpen(!open)} className="filter-heading">
        <h6>
          <BiCategory className="filter-icon" />
          Categories
        </h6>
      </div>
      <div className={open ? 'select-categories restaurant_timings' : 'select-categories restaurant_timings hide-mini'}>
        <ul className="filter-list cs-parent-checkbox-list">
          {
            categories?.map((row, index) => {
              return(
                <li key={index}>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={`category${index}`}
                      name="restaurant_timings_checkbox"
                      className
                      defaultValue="open"
                      onChange={(e) => handleChange(e, row.id)}
                    />
                    <label htmlFor={`category${index}`}>
                      {row.name}
                      {/* <span>(5)</span> */}
                    </label>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SearchResultSectionLeftMenuFilterOptionsCategories;
