import React from 'react';
import AccountSettings from '../../components/Profile/AccountSettings/AccountSettings';
import Addresses from '../../components/Profile/Addresses.js/Addresses';
import Favorites from '../../components/Profile/Favorites/Favorites';
import Info from '../../components/Profile/Info/Info';
import OrdersWrapper from '../../components/Profile/Orders/OrdersWrapper';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import Reviews from '../../components/Profile/Reviews/Reviews';
import SideBar from '../../components/Profile/SideBar';

const Profile = () => {
    const [currentStack, setCurrentStack] = React.useState('info');
    return (
        <div>
            <ProfileHeader />

            <div className="page-section account-header buyer-logged-in">
                <div className="container">
                    <div className="row">
                        <SideBar currentStack={currentStack} setCurrentStack={setCurrentStack} />
                        {
                            currentStack === 'fav' ? <Favorites /> :
                            currentStack === 'orders'? <OrdersWrapper /> : 
                            currentStack === 'settings'? <AccountSettings /> : 
                            currentStack === 'reviews'? <Reviews /> : 
                            currentStack === 'info'? <Info /> :
                            currentStack === 'addresses'? <Addresses /> : null

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile