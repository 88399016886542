import React from "react";

const MealCreatorCenterSectionTabsHolderTab3 = ({creator}) => {
  return (
    <div id="menu2" className="tab-pane fade">
      <div className="contact-info-detail">
        <h5>Overview {creator?.name}</h5>
        <p className="restaurant-desc" />
        <p>
          Base prepared fresh daily. Extra toppings are available in choose
          extraChoose you sauce: Go for BBQ sauce or piri piri sauce on your
          pizza base for no extra cost.Choose your cut: Triangular, square,
          fingers or Un cut on any size pizza
        </p>
        <div className="map-sec-holder">
          <div className="cs-map-section">
            <div className="cs-map">
              <div className="cs-map-content">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe width="100%" height="300" scrolling="no" src={`https://maps.google.com/maps?q=${creator?.latitude},${creator?.longitude}&hl=es;z=14&output=embed`}><a href="https://www.gps.ie/truck-gps/">delivery van gps</a></iframe>
                    Google Maps Generator by{" "}
                    <a href={`https://maps.google.com/maps?q=${creator?.latitude},${creator?.longitude}`}>Open In Google Maps</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="contact-info">
              <h5>Contact details</h5>
              <p>{creator?.address}</p>
              <ul>
                <li className="cell">
                  <i className="icon-phone" />
                  <a href="#">{creator?.phone}</a>
                </li>
                <li className="pizzaeast">
                  <i className="icon-globe2" />
                  <a href="https://techvillageco.com/">https://techvillageco.com/</a>
                </li>
                <li className="email">
                  <i className="icon-mail5" />
                  <a className="text-color" href="#">
                    Send Enquiry By Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="widget widget-timing">
              {/* <h5>Opening Hours</h5>
              <ul>
                <li>
                  <span>Monday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Tuesday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Wednesday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Thursday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Friday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Saturday</span>11:00 am - 11:00 pm
                </li>
                <li>
                  <span>Sunday</span>Off
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MealCreatorCenterSectionTabsHolderTab3;
