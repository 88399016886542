import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AutoComplete from "../components/SearchInput";

const BannerSearchMealCreatorInput = () => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = React.useState("");
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchKey.length > 0) {
      history.push(`/home-search/${searchKey}`);
    } else {
      history.push("/");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="col-lg-6 col-md-6 col-sm-5 col-xs-12">
          <div className="field-holder">
            <i style={{ top: "25px" }} className="icon-search" />
            <AutoComplete
              type="text"
              placeholder={`XChef ${t("Shared.Search")}`}
              onChange={(e) => setSearchKey(e.target.value)}
              setSearchKey={setSearchKey}
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
          <div className="field-holder">
            <input
              className="bgcolor"
              type="button"
              onClick={() => {
                if (searchKey.length > 0) {
                  history.push(`/home-search/${searchKey}`);
                }
              }}
              defaultValue={t("Shared.Search")}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default BannerSearchMealCreatorInput;
