import React from "react";

const MealCreatorTopSectionBannerLeft = ({creator}) => {
  return (
    <div className="company-info">
      <div className="img-holder">
        <figure>
          <img src={creator?.logo_original_path} alt="#" />
        </figure>
      </div>
      <div className="text-holder">
        <div className="rating-star">
          <span
            className="rating-box"
            style={{
              width: "100%"
            }}
          />
        </div>
        <span className="reviews">(1 Reviews)</span>
        <span className="restaurant-title">{creator?.name}</span>
        {/* <div className="text">
          <i className="icon-local_pizza" />
          <p>Apple Juice</p>
        </div> */}
      </div>
    </div>
  );
}

export default MealCreatorTopSectionBannerLeft;
