import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLikes } from "../../../redux/actions/cartActions";
import AppSpinner from "../../Spinner/Spinner";
import FavMeal from "./FavMeal";
import FavoriteItem from "./FavoriteItem";

function Favorites() {
  const dispatch = useDispatch();
  const [type, setType] = React.useState("Meal Creators");
  const [loading, setLoading] = React.useState();
  React.useEffect(() => {
    dispatch(getLikes(setLoading));
  }, []);
  const creators = useSelector(
    (state) => state?.cart?.likes?.likes?.meal_creator
  );
  const meals = useSelector((state) => state?.cart?.likes?.likes?.meal);
  const { t } = useTranslation();
  console.log("TYPEE", type);
  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <div style={{ width: "100%" }} className="user-dashboard loader-holder">
        <div className="user-holder">
          <div className="user-shortlist-list listing simple">
            <div className="element-title has-border right-filters-row">
              <h5>{t("Profile.Favorite")}</h5>
              <div className="right-filters row pull-right">
                <div className="col-lg-6 col-md-6 col-xs-6">
                  <div className="input-field">
                    <i className="icon-angle-down"></i>
                    <select
                      onChange={(e) => setType(e.target.value)}
                      style={{ fontSize: "12px" }}
                    >
                      <option className="creator">
                        {t("NavBar.MealCreators")}
                      </option>
                      <option className="meal">{t("NavBar.Meals")}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="flex-center">
                <AppSpinner />
              </div>
            ) : type === "Meal Creators" ? (
              <ul className="shortlists-list">
                {creators?.length !== 0 ? (
                  <div className="flex-center" style={{ height: "250px" }}>
                    <div>
                      <h2 className="sad-text">{t("Profile.EmptyWishlist")}</h2>
                      <h1 className="text-center">
                        <img
                          style={{ height: "150px", marginTop: "10px" }}
                          src="/assets/emptySaved.png"
                        />
                      </h1>
                    </div>
                  </div>
                ) : (
                  creators?.map((creator, index) => {
                    return <FavoriteItem key={index} creator={creator} />;
                  })
                )}
              </ul>
            ) : (
              <ul className="shortlists-list">
                {meals?.length === 0 ? (
                  <div className="flex-center" style={{ height: "250px" }}>
                    <div>
                      <h2 className="sad-text">{t("Profile.EmptyWishlist")}</h2>
                      <h1 className="text-center">
                        <img
                          style={{ height: "150px", marginTop: "10px" }}
                          src="/assets/emptySaved.png"
                        />
                      </h1>
                    </div>
                  </div>
                ) : (
                  meals?.map((meal, index) => {
                    return <FavMeal key={index} meal={meal?.likeable} />;
                  })
                )}
              </ul>
            )}
          </div>
          {/* <ul className="pagination">
                        <li className="active"><a>1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><span className="page-numbers dots">…</span></li>
                        <li><a href="#">24</a></li>
                        <li><a href="#">Next </a></li>
                    </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Favorites;
