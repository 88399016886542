import * as types from '../actions/types'

const initialState = {
    blogs: [],
    filters: [],
    loading: false,
    blog: {}
}

const blogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BLOGS:
            return {
                ...state,
                blogs: action.payload,
                loading: false
            };

        case types.GET_SINGLE_BLOG:
            return {
                ...state,
                blog: action.payload,
                loading: false
            };


        case types.GET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };

        case types.CREATORS_LOADING:
            return {
                ...state,
                loading: true
            };

        default:
            return state
    }

}

export default blogsReducer;