import React from 'react'
import { useTranslation } from 'react-i18next';
import LogoutModal from '../LogoutModal';

function SideBar({currentStack, setCurrentStack}) {
    const [show, setShow] = React.useState(false);
    const {t} = useTranslation();
    return (
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <LogoutModal show={show} setShow={setShow} title='Logout' />
            <div className="user-account-nav user-account-sidebar">
                <div className="user-nav-list">
                    <ul>
                        <li className={currentStack === 'info'? 'active': null}><a onClick={() => setCurrentStack('info')}><i className="icon-dashboard3"></i>Information</a></li>
                        {/* <li className={currentStack === 'reviews'? 'active' : null}><a onClick={() => setCurrentStack('reviews')}><i className="icon-comment2"></i>My Reviews</a></li> */}
                        <li className={currentStack === 'orders'? 'active' : null}><a onClick={() => setCurrentStack('orders')} className="btn-edit-profile"><i className="icon-add_shopping_cart"></i>{t("Profile.MyOrders")}</a></li>
                        <li className={currentStack === 'fav'? 'active' : null}><a onClick={() => setCurrentStack('fav')}><i className="icon-heart"></i>{t("Profile.Favorite")}</a></li>
                        <li className={currentStack === 'addresses'? 'active' : null}><a onClick={() => setCurrentStack('addresses')}><i className="icon-location"></i>{t("Profile.Addresses")}</a></li>

                        {/* <li><a href="buyer-statement.html"><i className="icon-file-text22"></i>Statement</a></li> */}
                        <li className={currentStack === 'settings'? 'active' : null}><a onClick={() => setCurrentStack('settings')}><i className="icon-build"></i>{t("Profile.AccountSettings")}</a></li>
                        {/* <li><a id="profile_delete"><i className="icon-delete"></i>Delete Profile</a></li> */}
                        <li><a onClick={() => setShow(true)} ><i className="icon-log-out"></i>{t("Profile.SignOut")}</a></li>
                    </ul>
                </div>
            </div>
        </div>
)
}

export default SideBar