import React from 'react'

function ProfileHeader() {
return (
        <div className="page-section restaurant-detail-image-section" style={{ background: 'url(assets/extra-images/banner-img-2.jpg) no-repeat scroll 0 0 / cover' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="company-info-detail">
                            <div className="company-info">
                                {/* <div className="img-holder">
                                    <figure>
                                        <img src="assets/extra-images/team-medium-img1.jpg" alt="#" />
                                    </figure>
                                </div> */}
                                <div className="text-holder">
                                    <span className="restaurant-title">{localStorage.getItem('userName')}</span>
                                    <ul className="user-info-contact">
                                    <li className="cell"><i className="icon-phone"></i><a href="tel:0123456789">{localStorage.getItem('userPhone')}</a></li>
                                        <li className="email"><i className="icon-mail5"></i><a href="mailto:dum4@chimpgroup.com">{localStorage.getItem('userEmail')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
}

export default ProfileHeader