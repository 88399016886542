import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/actions/types";

const AutoComplete = ({ setSearchKey, onChange, placeholder }) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const dispatch = useDispatch();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [show, setShow] = React.useState(false);
  const [suggArray, setSuggArray] = React.useState([]);
  const [input, setInput] = useState("");
  console.log(showSuggestions);
  const suggestions = suggArray?.map((sugg) => sugg?.text);

  const changeHandling = (e) => {
    onChange(e);
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  // const onClick = (suggestion) => {
  //   console.log("CLICKED");
  //   setFilteredSuggestions([]);
  //   setInput(suggestion);
  //   setActiveSuggestionIndex(0);
  //   setShowSuggestions(false);
  // };

  const SuggestionsListComponent = () => {
    return (
      filteredSuggestions.length && (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            // Flag the active suggestion with a class
            if (index === activeSuggestionIndex) {
              className = "suggestion-active";
            }
            return (
              <div
                className={`${className} suggestion`}
                onClick={() => {
                  console.log("CLICKED");
                  setInput(suggestion);
                  setShow(false);
                }}
                key={index}
              >
                {suggestion}
              </div>
            );
          })}
        </ul>
      )
    );
  };

  const getSugg = () => (dispatch) => {
    axios
      .get(
        `${BASE_URL}/${logged ? "user/search-history" : "popular-searches"}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: "KM",
        });
        setSuggArray(res?.data);
      })
      .catch((err) => console.log(err, "THE RESPONSE"));
  };

  React.useEffect(() => {
    console.log("THE RESPONSE CHECK");
    dispatch(getSugg());
  }, []);

  React.useEffect(() => {
    setSearchKey(input);
  }, [input]);
  const inputRef = React.useRef();
  return (
    <>
      <div className="suggestions-wrapper">
        <input
          ref={inputRef}
          type="text"
          onChange={changeHandling}
          onFocus={(e) => {
            changeHandling(e);
            setShow(true);
          }}
          onBlur={() =>
            setTimeout(() => {
              setShow(false);
            }, [500])
          }
          placeholder={placeholder}
          value={input}
        />
        {show ? <SuggestionsListComponent /> : null}
      </div>
    </>
  );
};
export default AutoComplete;
