import React from "react";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: "450px",
    maxWidth: "98%",
    borderRadius: "16px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "1200",
  },
};

function ReusableModal({ show, setShow, body, title }) {
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setShow(false);
  }

  return (
    <>
      <div>
        <Modal
          isOpen={show}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex-between">
            <h3 ref={(_subtitle) => (subtitle = _subtitle)}>{title}</h3>
            <IoIosClose
              onClick={() => setShow(false)}
              className="close-modal-symbol"
            />
          </div>
          <hr style={{ margin: "10px" }} />
          <div
            className="modal-body"
            style={{ overflowY: "scroll", maxHeight: "400px" }}
          >
            {body}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ReusableModal;
