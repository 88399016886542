import React from "react";
import FooterBottomLeft from "./FooterBottomLeft";
import FooterBottomRight from "./FooterBottomRight";

class FooterBottom extends React.Component {
  render() {
    return (
      <div className="copyright-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="copyright-inner">
                <FooterBottomLeft></FooterBottomLeft>
                <FooterBottomRight></FooterBottomRight>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterBottom;
