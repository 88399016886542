import React from "react";
import SearchResultSectionLeftMenuFilterOptionsCuisines from "./SearchResultSectionLeftMenuFilterOptionsCuisines";
import SearchResultSectionLeftMenuFilterOptionsCategories from "./SearchResultSectionLeftMenuFilterOptionsCategories";
import SearchResultSectionLeftMenuFilterOptionsNutri from "./SearchResultSectionLeftMenuFilterOptionsNutri";
import SearchResultSectionLeftMenuFilterOptionsProteins from "./SearchResultSectionLeftMenuFilterOptionsProteins";
import {useDispatch, useSelector} from 'react-redux'
import { getFilters } from "../redux/actions/creatorsActions";

const SearchResultSectionLeftMenuFilterOptions = ({setFilterData}) => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.creators.filters)
  const [selectedCuisines, setSelectedCuisines] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [selectedNutries, setSelectedNutries] = React.useState([]);
  const [selectedProteins, setSelectedProteins] = React.useState([]);

  // console.log('Cuisines', selectedCuisines)
  // console.log('Categories', selectedCategories)
  React.useEffect(() => {
    dispatch(getFilters())
  }, [])
  React.useEffect(() => {
    setFilterData({cuisines: selectedCuisines, categories: selectedCategories, proteins: selectedProteins, nutries: selectedNutries})
  }, [selectedCuisines, selectedCategories, selectedProteins, selectedNutries])

  return (
    <div className="foodbakery-filters listing-filter">
      <SearchResultSectionLeftMenuFilterOptionsCuisines selectedCuisines={selectedCuisines} setSelectedCuisines={setSelectedCuisines} cuisines={filters?.cuisines}></SearchResultSectionLeftMenuFilterOptionsCuisines>
      <SearchResultSectionLeftMenuFilterOptionsCategories selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} categories={filters?.categories}></SearchResultSectionLeftMenuFilterOptionsCategories>
      <SearchResultSectionLeftMenuFilterOptionsNutri selectedNutries={selectedNutries} setSelectedNutries={setSelectedNutries} nutri={filters?.nutritional_profiles}></SearchResultSectionLeftMenuFilterOptionsNutri>
      <SearchResultSectionLeftMenuFilterOptionsProteins selectedProteins={selectedProteins} setSelectedProteins={setSelectedProteins} proteins={filters?.protein_types}></SearchResultSectionLeftMenuFilterOptionsProteins>
    </div>
  );
}

export default SearchResultSectionLeftMenuFilterOptions;
