import React from "react";

class MealCreatorCenterSectionCategoriesTitle extends React.Component {
  render() {
    return (
      <div className="filter-toggle">
        <span className="filter-toggle-text">Categories By</span>
        <i className="icon-chevron-down" />
      </div>
    );
  }
}

export default MealCreatorCenterSectionCategoriesTitle;
