import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../components/Modal";
import LocalCartModal from "../components/LocalCartModal";
import { likeMeal } from "../redux/actions/cartActions";
import AuthModalWrapper from "../components/AuthModalWrapper";
import { useTranslation } from "react-i18next";

const MostPopularSectionMeal1 = ({ localCart, setLocalCart, meal }) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const [show, setShow] = React.useState();
  const [isFav, setIsFav] = React.useState(meal?.is_in_wishlist);
  const [authShow, setAuthShow] = React.useState(false);
  const [localShow, setLocalShow] = React.useState(false);
  const { t } = useTranslation();
  React.useEffect(() => {
    setIsFav(meal?.is_in_wishlist);
  }, [meal]);
  const dispatch = useDispatch();
  const callBack = () => {};
  return (
    <div className="col-md-4 col-xs-6 grid-listing-col ">
      <AuthModalWrapper show={authShow} setShow={setAuthShow} />
      <AppModal meal={meal} mealId={meal?.id} show={show} setShow={setShow} />
      <LocalCartModal
        localCart={localCart}
        setLocalCart={setLocalCart}
        meal={meal}
        mealId={meal?.id}
        show={localShow}
        setShow={setLocalShow}
      />
      <div
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => (logged ? setShow(true) : setLocalShow(true))}
      >
        <div className="img-holder">
          <figure>
            <img
              style={{
                height: "300px",
                objectFit: "cover",
                objectPosition: "center center",
              }}
              src={meal?.photos?.[0]?.photo_original_path}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png";
              }}
              alt=""
            />{" "}
            {meal?.is_offer ? (
              <span className="discount-span">
                {meal?.discount_percentage}%
              </span>
            ) : null}
            <figcaption className="listing-meta">
              <div className="listing-inner clearfix">
                <div className="list-option">
                  <span
                    className="label bgcolor time-label"
                    style={{
                      display: "none",
                    }}
                  />
                  <a
                    className="shortlist-btn"
                    onClick={() =>
                      logged
                        ? dispatch(
                            likeMeal(meal?.id, isFav, setIsFav, callBack)
                          )
                        : setAuthShow(true)
                    }
                  >
                    <i className={isFav ? "icon-heart" : "icon-heart-o"} />{" "}
                  </a>
                </div>
                <div className="list-rating">
                  <div className="rating-star">
                    <span
                      className="rating-box"
                      style={{
                        width: `${meal?.rating_avg * 20}%`,
                      }}
                    />
                  </div>
                  <span className="reviews">(1)</span>
                </div>
              </div>
            </figcaption>
          </figure>
          <span className="restaurant-status open">
            <em className="bookmarkRibbon" />
            Open
          </span>
        </div>
        <div className="text-holder">
          <div className="listing-inner" style={{ height: "150px" }}>
            <h4>
              <a>{meal.name}</a>
            </h4>
            <p>
              {meal.ingredients.length > 50
                ? meal.ingredients.substring(0, 50) + "..."
                : meal.ingredients}
              {meal.ingredients.length > 50 && (
                <span
                  onClick={() => (logged ? setShow(true) : setLocalShow(true))}
                  className="see-more-span"
                >
                  See More
                </span>
              )}
            </p>
            <div className="min-order">
              {t("Shared.MinOrder")}{" "}
              <span className="price">{meal.min_price} CA$</span>
            </div>
          </div>
          <div className="listing-footer">
            <div className="listing-inner clearfix">
              <div className="img-holder">
                <a>
                  <img src={meal.meal_creator?.logo_original_path} alt="" />
                </a>
              </div>
              <div className="text-holder">
                <p>{meal.meal_creator?.name}</p>
                <p className="deliver-time">
                  <span className="icon-motorcycle2" />
                  {meal.meal_creator?.delivery_from} min
                </p>{" "}
                <p className="pickup-time">
                  <span className="icon-clock4" />
                  {meal.meal_creator?.delivery_to} min
                </p>
                <a
                  onClick={() => (logged ? setShow(true) : setLocalShow(true))}
                  className="ordernow-btn bgcolor"
                >
                  {t("Shared.OrderNow")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopularSectionMeal1;
