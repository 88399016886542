import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../I18next";

const HeaderNav = () => {
  const { t } = useTranslation();
  return (
    <div className="main-nav">
      <nav id="site-navigation" className="main-navigation">
        <ul id="menu-main-menu" className="primary-menu">
          <li>
            <Link to="/">XChef</Link>
            {/*<ul>*/}
            {/*  <li>*/}
            {/*    <a href="/">Food Bakery</a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href="/">Mexican Restaurant</a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href="/">Food Stop</a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href="/">Rtl Demo</a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href="/">Food Court</a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href="/">Single Restaurant Demo</a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </li>
          <li>
            <Link to="/meal-creators/search/ ">{t("NavBar.MealCreators")}</Link>
          </li>
          <li>
            <Link to="/meals">{t("NavBar.Meals")}</Link>
          </li>
          <li>
            <Link to="/offers">{t("NavBar.Offers")}</Link>
          </li>
          <li>
            {/* <Link to="/about">About</Link> */}
            {/*/!*<ul>*!/*/}
            {/*/!*  <li>*!/*/}
            {/*/!*    <a href="/">Price Plans</a>*!/*/}
            {/*/!*  </li>*!/*/}
            {/*//   <li>*/}
            {/*//     <a href="/">How it works</a>*/}
            {/*//   </li>*/}
            {/*/!*  <li>*!/*/}
            {/*/!*    <a href="/">FAQ’s</a>*!/*/}
            {/*/!*  </li>*!/*/}
            {/*/!*  <li>*!/*/}
            {/*/!*    <a href="/">404</a>*!/*/}
            {/*/!*  </li>*!/*/}
            {/*/!*  <li>*!/*/}
            {/*/!*    <a href="/">Search Result</a>*!/*/}
            {/*/!*  </li>*!/*/}
            {/*/!*  <li>*!/*/}
            {/*/!*    <a href="/">Contact</a>*!/*/}
            {/*/!*  </li>*!/*/}
            {/*/!*</ul>*!/*/}
          </li>
          <li>
            <Link to="/blogs">{t("NavBar.Blogs")}</Link>
          </li>
          {localStorage.getItem("i18nextLng") !== "fr" ? (
            <li
              onClick={() => {
                i18n.changeLanguage("fr");
                window.location.reload();
              }}
            >
              <a>FR</a>
            </li>
          ) : (
            <li
              onClick={() => {
                i18n.changeLanguage("en");
                window.location.reload();
              }}
            >
              <a>EN</a>
            </li>
          )}
          {/* <li >
              <a href="#">Blogs</a>
            </li> */}
        </ul>
      </nav>
      {}
    </div>
  );
};

export default HeaderNav;
