export const BASE_URL = "https://new.dentohome.com/en/api";
export const GET_CREATORS = "GET_CREATORS";
export const GET_HOME = "GET_HOME";
export const HOME_LOADING = "HOME_LOADING";
export const CREATORS_LOADING = "CREATORS_LOADING";
export const GET_FILTERS = "GET_FILTERS";
export const FILTER_MEALS = "FILTER_MEALS";
export const GET_MEALS = "GET_MEALS";
export const MEALS_LOADING = "MEALS_LOADING";
export const GET_CREATOR_BY_ID = "GET_CREATOR_BY_ID";
export const LOGIN = "LOGIN";
export const GET_CART = "GET_CART";
export const COMPLETE_REGISTERATION = "COMPLETE_REGISTRATION";
export const LOGOUT = "LOGOUT";
export const GET_LIKES = "GET_LIKES";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_OFFERS = "GET_OFFERS";
export const GET_USER = "GET_USER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_BLOGS = "GET_BLOGS";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_CHAT = "GET_CHAT";
export const GET_ZONES = "GET_ZONES";
export const LOCAL_CART_UPDATE = "LOCAL_CART_UPDATE";
export const CLEAR_CART = "CLEAR_CART";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const GET_CHAT_SCROLLEDd = "GET_CHAT_SCROLLED";
