import React from "react";
import MostPopularSectionTitle from "./MostPopularSectionTitle";
import MostPopularSectionList from "./MostPopularSectionList";

const MostPopularSection = ({ localCart, setLocalCart, meals, offers }) => {
  return (
    <div
      className="page-section nopadding cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "100px",
        paddingBottom: "60px",
        marginBottom: "0px",
        background: "#ffffff",
      }}
    >
      <div className="container">
        <div className="row">
          <MostPopularSectionTitle offers={offers}></MostPopularSectionTitle>
          <MostPopularSectionList
            localCart={localCart}
            setLocalCart={setLocalCart}
            meals={meals}
          ></MostPopularSectionList>
        </div>
      </div>
    </div>
  );
};

export default MostPopularSection;
