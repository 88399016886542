import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AuthModalWrapper from "../components/AuthModalWrapper";
import ReusableModal from "../components/ReusableModal";
import Review from "../components/Review";
import { getCreatorById } from "../redux/actions/creatorsActions";

const MealCreatorCenterSectionTabsHolderTab2 = ({ creator }) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const dispatch = useDispatch();
  const { creatorId } = useParams();
  const callBack = () => {
    dispatch(getCreatorById(creatorId));
  };
  const [show, setShow] = React.useState(false);
  const [authShow, setAuthShow] = React.useState(false);
  return (
    <div id="menu1" className="tab-pane fade">
      <AuthModalWrapper show={authShow} setShow={setAuthShow} />
      <ReusableModal
        show={show}
        setShow={setShow}
        title="Review Meal Creator"
        body={<Review callBack={callBack} setShow={setShow} />}
      />
      <div className="flex-between write-review-button-wrapper">
        <h3>Reviews</h3>
        <button onClick={() => (logged ? setShow(true) : setAuthShow(true))}>
          Write Your Review
        </button>
      </div>
      <div className="reviews-holder">
        {/* <div
          className="add-new-review-holder"
          style={{
            display: "none"
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="elements-title">
                <h3>Rate and Write a Review</h3>
                <a href="#" className="close-post-new-reviews-btn">
                  Close
                </a>
              </div>
            </div>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style={{
                textAlign: "center"
              }}
            >
              Please login in order to post review.
            </div>
          </div>
        </div> */}
        <div className="reviwes-restaurant-holder">
          {/* <div className="over-all-rating-holder"> */}
          {/* <div className="overall-ratings-container">
              <div className="overall-rating">
                <h6>Customer Reviews For Food Bakery</h6>
                <ul className="reviews-box">
                  <li>
                    <em>5.0 </em>
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{
                          width: "100%"
                        }}
                      />
                    </div>
                    <span className="reviews-count">
                      (based on 1 reviews)
                    </span>
                  </li>
                  <li>
                    <div className="icon-holder">
                      <i className="icon-smile" />
                    </div>
                    <p>
                      <span>100%</span> of diners recommend this restaurant
                    </p>
                  </li>
                </ul>
              </div>
            </div> */}
          {/* <div className="ratings-summary-container">
              <div className="rating-summary">
                <h5>Rating summary</h5>
                <ul>
                  <li>
                    <span className="review-category">Service</span>
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{
                          width: "100%"
                        }}
                      />
                    </div>
                  </li>
                  <li>
                    <span className="review-category">Quality</span>
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{
                          width: "100%"
                        }}
                      />
                    </div>
                  </li>
                  <li>
                    <span className="review-category">Value</span>
                    <div className="rating-star">
                      <span
                        className="rating-box"
                        style={{
                          width: "100%"
                        }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          {/* </div> */}
          <div className="review-listing">
            <div className="elements-title">
              <h5>
                Customer Reviews For Restaurant Demo <span>1</span>
              </h5>
              <div className="sort-by">
                <span
                  className="ajax-loader-sorty-by"
                  style={{
                    display: "none",
                  }}
                >
                  <img src="/assets/images/ajax-loader.gif" alt />
                </span>
                {/* <ul className="reviews-sortby">
                  <li>
                    <span className="active-sort">Newest Reviews</span>
                    <div className="reviews-sort-dropdown">
                      <form>
                        <div className="input-reviews">
                          <div className="radio-field">
                            <input
                              name="review"
                              id="check-1"
                              type="radio"
                              defaultValue="newest"
                              defaultChecked="checked"
                            />
                            <label htmlFor="check-1">Newest Reviews</label>
                          </div>
                          <div className="radio-field">
                            <input
                              name="review"
                              id="check-2"
                              type="radio"
                              defaultValue="highest"
                            />
                            <label htmlFor="check-2">Highest Rating</label>
                          </div>
                          <div className="radio-field">
                            <input
                              name="review"
                              id="check-3"
                              type="radio"
                              defaultValue="lowest"
                            />
                            <label htmlFor="check-3">Lowest Rating</label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
            <ul className="review-restaurant">
              {creator?.reviews?.map((review, index) => {
                return (
                  <li
                    key={index}
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                  >
                    <div className="list-holder ">
                      <div className="review-text">
                        <div className="review-title">
                          {/* <h6>: Best Food </h6> */}
                          <div className="rating-holder">
                            <div className="rating-star">
                              <span
                                style={{
                                  width: "100%",
                                }}
                                className="rating-box"
                              />
                            </div>
                          </div>
                        </div>
                        <em className="review-date">
                          {new Date(review.created_at).toLocaleString()}
                        </em>
                        <p>{review?.comment}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealCreatorCenterSectionTabsHolderTab2;
