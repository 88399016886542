import React from 'react';
import SearchTopSection from "../../components_search/SearchTopSection";
import SearchResultSection from "../../components_search/SearchResultMeals";

export const MealsPage = () => {
  const [localCart, setLocalCart] = React.useState(localStorage.getItem('localCart') ? JSON.parse(localStorage.getItem('localCart')) : []);
  const [searchKey, setSearchKey] = React.useState();
  return (
    
    <>
      <SearchTopSection setSearchKey={setSearchKey}></SearchTopSection>
      <SearchResultSection localCart={localCart} setLocalCart={setLocalCart} searchKey={searchKey}></SearchResultSection>
    </>
  );
};
   