import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthModalWrapper from "../components/AuthModalWrapper";
import { likeCreator } from "../redux/actions/cartActions";

const FeaturedMealCreatorSectionMealCreator1 = ({
  name,
  address,
  logo,
  minimum,
  maximum,
  phone,
  id,
  creator,
}) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const history = useHistory();
  const [isFav, setIsFav] = React.useState(creator?.is_in_wishlist);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setIsFav(creator?.is_in_wishlist);
  }, [creator]);
  const dispatch = useDispatch();
  return (
    <li className="col-lg-6 col-md-6 col-sm-6 col-xs-12 meal-creator-card">
      <AuthModalWrapper show={show} setShow={setShow} />
      <div className="list-post ">
        <div className="img-holder">
          <figure>
            <a>
              <img src={logo} alt="" />
            </a>
          </figure>
        </div>
        <span className="restaurant-status close">
          <em className="bookmarkRibbon" />
          Close
        </span>
        <div className="text-holder">
          <div className="post-title">
            <h5 onClick={() => history.push(`/view-creator/${id}`)}>
              <a>{name}</a>
            </h5>
          </div>
          <address>{address}</address>
          <div className="delivery-potions">
            <div className="post-time">
              <i className="icon-check_circle" />
              Min £15.00
            </div>
            <div className="post-time">
              <i className="icon-motorcycle" />
              {minimum} min
            </div>
            <div className="post-time">
              <i className="icon-clock4" />
              {maximum} min
            </div>
            <div className="list-rating">
              <div className="rating-star">
                <span
                  className="rating-box"
                  style={{
                    width: `${creator?.rating_avg * 20}%`,
                  }}
                />
              </div>
              <span className="reviews">(1)</span>
            </div>
            <span>{phone}</span>
          </div>
        </div>
        <div className="list-option">
          <a
            className="shortlist-btn"
            onClick={() =>
              logged
                ? dispatch(likeCreator(creator?.id, isFav, setIsFav))
                : setShow(true)
            }
          >
            <i className={isFav ? "icon-heart" : "icon-heart-o"} />
          </a>
        </div>
      </div>
    </li>
  );
};

export default FeaturedMealCreatorSectionMealCreator1;
