import React from "react";
import { useTranslation } from "react-i18next";

const MostPopularSectionTitle = ({ offers }) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div
        className="cs-section-title"
        style={{
          textAlign: "center!important",
        }}
      >
        {offers ? (
          <h2>{t("NavBar.Offers")}</h2>
        ) : (
          <h2>{t("Home.ChooseFromMostPopular")}</h2>
        )}
        <span
          style={{
            color: "#aaaaaa",
          }}
        >
          {/* Explore restaurants, bars, and cafés by locality */}
        </span>
      </div>
    </div>
  );
};

export default MostPopularSectionTitle;
