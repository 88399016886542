import React from "react";
import { useTranslation } from "react-i18next";
import OrderDetails from "./OrderDetails";
import { BiMessageMinus } from "react-icons/bi";
import MealCreatorChatModal from "../../MealCreatorChatModal";

function SingleOrder({ order, orders, setCurrentOrder, currentOrder }) {
  const { t } = useTranslation();
  const [chatShow, setChatShow] = React.useState(false);
  console.log("CURR", currentOrder);
  return (
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <MealCreatorChatModal
        show={chatShow}
        setShow={setChatShow}
        chatId={order?.conversation?.id}
      />
      <div className="order-list">
        <div className="author-info">
          <div className="img-holder">
            <figure>
              <a href="#">
                <img src={order?.meal_creator?.logo_original_path} alt="#" />{" "}
              </a>
            </figure>
          </div>
          <div className="text-holder">
            <h6>
              <a href="listing-detail.html">{order?.meal_creator?.name}</a>
            </h6>
            <address>Apple Juice</address>
            <span className="price">{order?.total} CA$</span>
          </div>
        </div>
        <div className="post-time">
          {/* <span style={{fontSize: '14px'}}>{order?.meal_creator?.address}</span> */}
          <span>
            {t("Orders.Deliverin")} {order?.meal_creator?.delivery_from}{" "}
            {t("Orders.Mins")} ~ {order?.meal_creator?.delivery_to}{" "}
            {t("Orders.Mins")}{" "}
          </span>
        </div>
        <div className="list-rating">
          <div className="rating-star">
            <span
              className="rating-box"
              style={{
                width: `${order?.meal_creator?.rating_avg * 20}%`,
              }}
            />
          </div>
          <span className="reviews">(1)</span>
        </div>
        <span className="date-time">
          {new Date(order?.created_at).toDateString() +
            " " +
            new Date(order?.created_at).toLocaleTimeString()}{" "}
        </span>
        <div className="flex-between">
          <div
            className="order-btn"
            onClick={() =>
              setCurrentOrder(orders?.find((row) => row?.id === order?.id))
            }
          >
            <a href="#" data-toggle="modal" data-target="#order_detail">
              {t("Orders.OrderDetails")}
            </a>
            <span className="order-status" stoole="background-color: #047a06;">
              Completed
            </span>
          </div>
          <div className="order-btn" onClick={() => setChatShow(true)}>
            <a href="#">
              <BiMessageMinus />
            </a>
          </div>
        </div>
        <div
          className="modal fade menu-order-detail order-detail"
          id="order_detail"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h2>{t("Orders.OrderDetails")}</h2>
              </div>
              <OrderDetails order={currentOrder} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleOrder;
