import React from "react";
import BannerSearchMealCreatorInput from "./BannerSearchMealCreatorInput";
// import BannerSearchButton from "./BannerSearchButton";

class BannerSearch extends React.Component {
  render() {
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="main-search fancy modern">
          <div className="row text-center">
            <BannerSearchMealCreatorInput></BannerSearchMealCreatorInput>
            {/* <BannerSearchButton></BannerSearchButton> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BannerSearch;
