import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import BlogCard from '../../components/BlogCard';
import AppSpinner from '../../components/Spinner/Spinner';
// import AppSpinner from '../../components/Spinner/Spinner';
import Banner from '../../components_home/Banner';
import { getBlogs } from '../../redux/actions/blogsActions';
import { getOffers } from '../../redux/actions/mealsActions';

function Blogs() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        dispatch(getOffers(() => { }))
        dispatch(getBlogs(setLoading))
    }, [])
    const blogs = useSelector(state => state?.blogs?.blogs?.blogs)
    console.log(blogs)
    return (
        <div>
            <Banner offers />
            {loading ? <div className='p-5'>
                <AppSpinner />
            </div> :
                <div style={{ marginTop: '80px' }} className='container'>
                    <h2 className='blogs-header'>{t("NavBar.Blogs")}</h2>
                    <div className="main-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="page-section">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="blog blog-masonry">
                                                    <div className="row">
                                                        {
                                                            blogs?.map((blog) => {
                                                                return (
                                                                    <BlogCard key={blog.id} blog={blog} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <nav>
                                            <ul className="pagination">
                                                <li><a className="prev page-numbers"> Prev</a></li>
                                                <li><a className="page-numbers active">1</a></li>
                                                <li><a className="page-numbers" href="#">2</a></li>
                                                <li><a className="page-numbers" href="#">3</a></li>
                                                <li><a className="page-numbers" href="#">4</a></li>
                                                <li><a className="next page-numbers" href="#">Next</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Blogs