import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AuthModalWrapper from "../components/AuthModalWrapper";
import { likeCreator } from "../redux/actions/cartActions";

const SearchResultSectionCenterListResult1 = ({ creator }) => {
  const { t } = useTranslation();
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;

  const [show, setShow] = React.useState();
  const [isFav, setIsFav] = React.useState(creator?.is_in_wishlist);
  React.useEffect(() => {
    setIsFav(creator?.is_in_wishlist);
  }, [creator]);
  const dispatch = useDispatch();
  return (
    <li>
      <div className="img-holder">
        <AuthModalWrapper show={show} setShow={setShow} />
        <figure>
          <a>
            <img
              src={creator.logo_original_path}
              className="img-list wp-post-image"
              alt="#"
            />
          </a>
        </figure>
        <span className="restaurant-status close">
          <em className="bookmarkRibbon" />
          Close
        </span>
      </div>
      <div className="text-holder my-text-holder">
        <div className="list-rating">
          <div className="rating-star">
            <span
              className="rating-box"
              style={{
                width: `${creator?.rating_avg * 20}%`,
              }}
            />
          </div>
          <span className="reviews">(1)</span>
        </div>
        <div>
          <div className="post-title">
            <h5>
              <Link to={`/view-creator/${creator?.id}`}>
                <a>{creator.name} </a>
              </Link>
            </h5>
          </div>
        </div>
        <span className="post-categories">
          <span>Type of food :</span> Apple Juice
        </span>
        <div className="delivery-potions">
          <div className="post-time">
            <i style={{ marginRight: "3px" }} className="icon-motorcycle" />
            <div className="time-tooltip">
              <div className="time-tooltip-holder">
                {" "}
                <b className="tooltip-label">Delivery time</b>{" "}
                <b className="tooltip-info">
                  Your order will be delivered in {creator.delivery_from}{" "}
                  minutes.
                </b>{" "}
              </div>
            </div>
          </div>
          <div className="post-time">
            <i className="icon-clock4" />
            <div className="time-tooltip">
              <div className="time-tooltip-holder">
                {" "}
                <b className="tooltip-label">Pickup time</b>{" "}
                <b className="tooltip-info">
                  You can pickup order in {creator.delivery_to} minutes.
                </b>{" "}
              </div>
            </div>
          </div>
          <span>{creator.address}</span>
        </div>
      </div>
      <div className="list-option">
        <a
          className="shortlist-btn"
          onClick={() =>
            logged
              ? dispatch(likeCreator(creator?.id, isFav, setIsFav))
              : setShow(true)
          }
        >
          <i className={isFav ? "icon-heart" : "icon-heart-o"} />
        </a>
        <Link
          to={`/view-creator/${creator?.id}`}
          className="viewmenu-btn text-color"
        >
          {t("Shared.ViewMenu")}
        </Link>
      </div>
    </li>
  );
};

export default SearchResultSectionCenterListResult1;
