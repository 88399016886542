import * as types from '../actions/types'

const initialState = {
    meals: [],
    filters: [],
    loading: false,
    offers: []
}

const mealsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MEALS:
            return {
                ...state,
                meals: action.payload,
                loading: false
            };

        case types.GET_OFFERS:
            return {
                ...state,
                offers: action.payload,
                loading: false
            };

        
        case types.MEALS_LOADING:
            return {
                loading: true
            };

        default:
            return state
    }

}

export default mealsReducer;