import React from "react";
import { useTranslation } from "react-i18next";
import { BsChatDotsFill } from "react-icons/bs";
import ChatModal from "../components/ChatModal";

const FooterTopInfoConnect = () => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState();

  // React.useEffect(() => setShow(true));
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <ChatModal show={show} setShow={setShow} />
      <div className="widget widget-connect">
        <div className="widget-title">
          <h5> {t("Footer.Connect")}</h5>
        </div>
        <ul className="social-media">
          <li>
            <a href="#" data-original-title="twitter">
              <i className=" icon-twitter" />
            </a>
          </li>
          <li>
            <a href="#" data-original-title="linkedin">
              <i className="icon-linkedin" />
            </a>
          </li>
          <li>
            <a href="#" data-original-title="pinterest">
              <i className="icon-pinterest" />
            </a>
          </li>
          <li>
            <a href="#" data-original-title="google">
              <i className="icon-google" />
            </a>
          </li>
          <li>
            <a href="#" data-original-title="instagram">
              <i className="icon-instagram" />
            </a>
          </li>
          <li>
            <a href="#" data-original-title="youtube">
              <i className="icon-youtube" />
            </a>
          </li>
        </ul>
        <ul>
          <a href="tel: +1 514 690 0000">
            <li>
              <span className="bgcolor">
                <i className="icon-ring_volume" />
              </span>
              <p>+1 514 690 0000</p>
            </li>
          </a>
          <a href="mailto: support@xchef.com">
            <li>
              <span className="bgcolor">
                <i className="icon-envelope-o" />
              </span>
              <p>support@xchef.com</p>
            </li>
          </a>
          {localStorage.getItem("auth") === "true" && (
            <li style={{ cursor: "pointer" }} onClick={() => setShow(true)}>
              <span className="bgcolor">
                <BsChatDotsFill />
              </span>
              <p>Chat with us</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FooterTopInfoConnect;
