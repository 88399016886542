import axios from "axios";
import { myToast } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getOrders = (setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .get(`${types.BASE_URL}/user/orders`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_ORDERS,
        payload: res.data,
      });
    })
    .finally(() => setLoading(false));
};

export const addItemToCart =
  (itemData, callBack, setError, setLoading) => (dispatch) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("meal_size_id", itemData.sizeId);
    formData.append("meal_id", itemData.mealId);
    formData.append("quantity", itemData.quantity);
    itemData?.options?.map((option) =>
      formData.append("meal_option_id[]", option.id)
    );
    axios
      .post(`${types.BASE_URL}/user/update-or-add-to-cart`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        dispatch({
          type: "S",
        });
        setLoading(false);
        callBack();
        myToast(
          lang === "fr"
            ? "L'article a été ajouté avec succès"
            : "Item has been added successfully"
        );
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

export const clearCart = (callBack, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(
      `${types.BASE_URL}/user/empty-cart`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: "S",
      });
      setLoading(false);
      callBack();
      myToast(
        lang === "fr"
          ? "Le panier a été effacé avec succès"
          : "Cart has been cleared successfully"
      );
    })
    .catch(() => {
      setLoading(false);
    });
};

export const removeCartItem = (callBack, setLoading, itemId) => (dispatch) => {
  setLoading(true);
  axios
    .post(
      `${types.BASE_URL}/user/remove-cart-item`,
      { cart_item_id: itemId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: "S",
      });
      setLoading(false);
      callBack();
      myToast(
        lang === "fr"
          ? "L'article a été supprimé avec succès"
          : "Item has been removed successfully"
      );
    })
    .catch(() => {
      setLoading(false);
    });
};

export const editItemQuantity =
  (callBack, setLoading, itemId, quantity) => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${types.BASE_URL}/user/update-item-quantity`,
        { cart_item_id: itemId, quantity },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: "S",
        });
        setLoading(false);
        callBack();
        myToast("Item quantity has been edited successfully");
      })
      .catch(() => {
        setLoading(false);
      });
  };

export const getLikes = (setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .get(`${types.BASE_URL}/user/likes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_LIKES,
        payload: res.data,
      });
      setLoading(false);
    })
    .catch(() => setLoading(false));
};

export const likeMeal = (id, isFav, setIsFav, callBack) => (dispatch) => {
  axios
    .post(
      `${types.BASE_URL}/user/like-meal`,
      { meal_id: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setIsFav(!isFav);
      dispatch({ type: "ss" });
      myToast(res?.data?.message);
      callBack();
    })
    .catch((err) => console.log(err.response));
};

export const likeCreator = (id, isFav, setIsFav, callBack) => (dispatch) => {
  axios
    .post(
      `${types.BASE_URL}/user/like-meal-creator`,
      { meal_creator_id: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setIsFav(!isFav);
      dispatch({ type: "ss" });
      myToast(res?.data?.message);
      callBack();
    })
    .catch((err) => console.log(err.response));
};
