import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/actions/types";
import { myToast } from "../../Toast";

function ChangeEmail() {
  const { t } = useTranslation();
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState(localStorage.getItem("userEmail"));
  const [err, setErr] = React.useState();
  const [loading, setLoading] = React.useState();
  const dispatch = useDispatch();
  const changeEmail = () => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/user/update-email`,
        { email, password },
        {
          headers: {
            Authorization: `Beare ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        myToast(t("Toast.Email has been changed successfully"));
        dispatch({ type: "RR" });
        setLoading(false);
        setErr([]);
      })
      .catch((err) => {
        setErr(err?.response?.data);
        setLoading(false);
      });
  };

  console.log("err", err);
  const handleSubmit = () => {
    dispatch(changeEmail());
  };
  return (
    <div>
      <hr />
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="element-title has-border">
          <h5>{t("Profile.ChangeEmail")}</h5>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <label> {t("Auth.Password")}*</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            minLength={6}
          />
          <span className="red">
            {err?.errors?.password ? err?.errors?.password[0] : null}
          </span>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <label>{t("Profile.NewEmail")} *</label>
          <input
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className="foodbakery-dev-req-field"
          />
          <span className="red">
            {err?.errors?.email ? err?.errors?.email[0] : null}
          </span>
        </div>
      </div>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div className="field-holder">
          <button
            onClick={handleSubmit}
            name="button"
            type="button"
            className="btn-submit"
          >
            {loading ? (
              <BiLoaderAlt className="login-loader" />
            ) : (
              t("Profile.ChangeEmail")
            )}
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default ChangeEmail;
