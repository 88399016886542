import React from "react";
import MealCreatorCenterSectionCategoriesTitle from "./MealCreatorCenterSectionCategoriesTitle";
import MealCreatorCenterSectionCategoriesList from "./MealCreatorCenterSectionCategoriesList";

const MealCreatorCenterSectionCategories = ({categories}) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12 sticky-sidebar">
      <MealCreatorCenterSectionCategoriesTitle categories={categories}></MealCreatorCenterSectionCategoriesTitle>
      <MealCreatorCenterSectionCategoriesList categories={categories}></MealCreatorCenterSectionCategoriesList>
    </div>
  );
}

export default MealCreatorCenterSectionCategories;
