import React from "react";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/authActions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: "450px",
    maxWidth: "98%",
    borderRadius: "16px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "1200",
  },
};

function LogoutModal({ show, setShow, title }) {
  const { t } = useTranslation();

  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setShow(false);
  }

  const dispatch = useDispatch();

  const history = useHistory();

  return (
    <>
      <div>
        <Modal
          isOpen={show}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex-between">
            <h3 ref={(_subtitle) => (subtitle = _subtitle)}>{title}</h3>
            <IoIosClose
              onClick={() => setShow(false)}
              className="close-modal-symbol"
            />
          </div>
          <hr />
          <div className="modal-body">
            <div>
              <h5 className="text-center">
                {t("Confirmation.Are you sure that you want to logout?")}
              </h5>
              <div className="flex-between mt-4">
                <button
                  onClick={() => setShow(false)}
                  className="clear-no-button"
                >
                  NO
                </button>
                <button
                  onClick={() => {
                    history.push("/");
                    dispatch(logout());
                  }}
                  className="clear-yes-button"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default LogoutModal;
