import React from "react";
import { useSelector } from "react-redux";
import AppSpinner from "../components/Spinner/Spinner";
import MostPopularSectionMeal1 from "./MostPopularSectionMeal1";
import { t } from "i18next";

const MostPopularSectionList = ({ localCart, setLocalCart, meals }) => {
  // const meals = useSelector(state => state.home.meals);
  const loading = useSelector((state) => state.home.loading);
  console.log("MEALS", meals);
  return loading ? (
    <AppSpinner />
  ) : (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="row">
        {meals?.length === 0 ? (
          <h3 className="text-center">{t("Shared.NoResults")}</h3>
        ) : (
          <div className="listing grid-listing three-cols">
            {meals?.map((meal, index) => (
              <MostPopularSectionMeal1
                localCart={localCart}
                setLocalCart={setLocalCart}
                meal={meal}
                key={index}
              ></MostPopularSectionMeal1>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MostPopularSectionList;
