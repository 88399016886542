import React from "react";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { MdMenu } from "react-icons/md";
import MobileNav from "./MobileNav";

const MainHeader = () => {
  const [hide, setHide] = React.useState(true);
  return (
    <div className="main-header">
      <div className="wide">
        <div className="row">
          <HeaderLeft></HeaderLeft>
          <div className="col-lg-0 col-md-0 col-xs-6 col-sm-6 drag-icon-section">
            <div onClick={() => setHide(!hide)} className="drag-icon-wrapper">
              <MdMenu className="drag-icon" />
            </div>
          </div>
          <div className={`mobile-nav ${hide ? "hide-mobile-nav" : null}`}>
            <MobileNav setHide={setHide} />
          </div>
          <HeaderRight></HeaderRight>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
