import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import ChangePasswordConfirm from "./ChangePasswordForget";
import CompleteRegistration from "./CompleteRegistration";
import ForgetPassword from "./ForgetPasswowrd";
import Login from "./Login";
import Registration from "./Registeration";
import VerifyCodeRegister from "./VerifyCodeRegister";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: "380px",
    maxWidth: "98%",
    borderRadius: "4px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "1200",
  },
};

function AuthModalWrapper({ show, setShow }) {
  const { t } = useTranslation();
  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setShow(false);
  }

  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const isCompleted = useSelector((state) => state.auth.isCompleted);

  React.useEffect(() => {
    if (localStorage.getItem("isRegistered") === "0") {
      setStack("complete-re");
    } else if (isCompleted === 0) {
      setStack("complete-re");
    }
  }, [logged]);

  // React.useEffect(() => {
  //     setStack('login')
  // }, [show])

  const [stack, setStack] = React.useState("login");
  const [registrationNumber, setRegisterationNumber] = React.useState("");
  const [forgetPasswordNumber, setForgetPasswordNumber] = React.useState("");
  console.log(forgetPasswordNumber);

  const pageTitle =
    stack === "login"
      ? t("Auth.Login")
      : stack === "register"
      ? t("Auth.Register")
      : stack === "forget-password"
      ? t("Auth.ForgetPassword")
      : stack === "verify-register"
      ? t("Auth.VerifyCode")
      : stack === "forget-verify"
      ? t("Auth.ForgetPassword")
      : stack === "complete-re"
      ? t("Auth.CompleteRegistration")
      : null;

  return (
    <>
      <div>
        <Modal
          isOpen={show}
          onAfterOpen={afterOpenModal}
          onRequestClose={stack === "complete-re" ? null : closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex-between">
            <h4 ref={(_subtitle) => (subtitle = _subtitle)}>{pageTitle}</h4>
            {isCompleted ? (
              <IoIosClose
                onClick={() => setShow(false)}
                className="close-modal-symbol"
              />
            ) : (
              <span></span>
            )}
          </div>
          <hr />
          <div className="login-form">
            {stack === "login" ? (
              <Login setShow={setShow} setStack={setStack} />
            ) : stack === "register" ? (
              <Registration
                setRegisterationNumber={setRegisterationNumber}
                setShow={setShow}
                setStack={setStack}
              />
            ) : stack === "forget-password" ? (
              <ForgetPassword
                setForgetPasswordNumber={setForgetPasswordNumber}
                setShow={setShow}
                setStack={setStack}
              />
            ) : stack === "verify-register" ? (
              <VerifyCodeRegister
                phone={registrationNumber}
                setShow={setShow}
              />
            ) : stack === "complete-re" ? (
              <CompleteRegistration setShow={setShow} />
            ) : stack === "forget-verify" ? (
              <ChangePasswordConfirm
                phone={forgetPasswordNumber}
                setShow={setShow}
              />
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AuthModalWrapper;
