import './style.css'

const AppSpinner = () => {

    return(
        <div style={{width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center', margin: '20px'}}>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default AppSpinner;