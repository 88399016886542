import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../components/Modal";
import LocalCartModal from "../components/LocalCartModal";
import { likeMeal } from "../redux/actions/cartActions";
import AuthModalWrapper from "../components/AuthModalWrapper";

const MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1 = ({
  localCart,
  setLocalCart,
  meal,
}) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  const [authShow, setAuthShow] = React.useState();
  const [show, setShow] = React.useState(false);
  const [localShow, setLocalShow] = React.useState(false);
  const [isFav, setIsFav] = React.useState(meal?.is_in_wishlist);
  React.useEffect(() => {
    setIsFav(meal?.is_in_wishlist);
  }, [meal]);
  const dispatch = useDispatch();
  const callBack = () => {};

  return (
    <li style={{ position: "relative" }} id="main">
      {meal?.is_offer ? (
        <span className="discount-span-inside">
          {meal?.discount_percentage}%
        </span>
      ) : null}
      <AuthModalWrapper show={authShow} setShow={setAuthShow} />
      <AppModal
        creatorId={meal?.meal_creator_id}
        meal={meal}
        mealId={meal?.id}
        show={show}
        setShow={setShow}
      />
      <LocalCartModal
        localCart={localCart}
        setLocalCart={setLocalCart}
        meal={meal}
        mealId={meal?.id}
        show={localShow}
        setShow={setLocalShow}
      />
      <div className="image-holder">
        {" "}
        <a>
          <img
            style={{ maxHeight: "65px" }}
            src={meal?.photos[0]?.photo_original_path}
            alt="#"
          />
        </a>
      </div>
      <div className="text-holder">
        <h6>{meal?.name}</h6>
        <span>{meal?.ingredients}</span>
        <a
          style={{ color: "#6e2230" }}
          onClick={() =>
            logged
              ? dispatch(likeMeal(meal?.id, isFav, setIsFav, callBack))
              : setAuthShow(true)
          }
        >
          <i className={isFav ? "icon-heart" : "icon-heart-o"} />{" "}
        </a>
        {/* <ul className="nutri-icons">
          <li>
            <a data-toggle="tooltip" title="Contain Bnana">
              <img src="/assets/extra-images/natural-item03.png" alt="#" />
            </a>
          </li>
        </ul> */}
      </div>
      <div className="price-holder">
        <span className="price">£{meal?.min_price}</span>
        <a
          href="#."
          data-toggle="modal"
          data-target="#extras-0-1"
          className="dev-adding-menu-btn"
          onClick={() => (logged ? setShow(true) : setLocalShow(true))}
        >
          <i className="icon-plus4 text-color" />
        </a>
        <span id="add-menu-loader-0" />
      </div>
    </li>
  );
};

export default MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1;
