import React from "react";
import CartOrderResturant from "../../components_old/cart-order-resturant";
import { Fade } from "react-reveal";
import { useSelector } from "react-redux";
import LocalCart from "../../components_old/cart-order-resturant/LocalCart";
import { IoMdArrowForward } from "react-icons/io";
// import "./App.css";

function SideDrawer({ isDrawerOpen, setIsDrawerOpen }) {
  const handleOverlayClick = () => {
    setIsDrawerOpen(false);
  };

  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;

  return (
    <div className="side-drawer">
      {isDrawerOpen && (
        <>
          <Fade>
            <div className="Overlay" onClick={handleOverlayClick}></div>
            <div className="SideDrawer">
              <IoMdArrowForward
                size={20}
                style={{ cursor: "pointer", marginTop: "10px" }}
                onClick={() => setIsDrawerOpen(false)}
              />
              <div style={{ marginTop: "-40px" }}>
                {logged ? <CartOrderResturant /> : <LocalCart />}
              </div>
            </div>
          </Fade>
        </>
      )}
    </div>
  );
}

export default SideDrawer;
