import React from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions";
import { myError } from "./Toast";
import { BiLoaderAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({ setStack, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  // const history = useHistory();
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const callBack = () => window.location.reload();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length !== 11) {
      myError(t("Toast.Phone number must be 11 charts"));
    } else if (password.length < 6) {
      myError(t("Toast.Password must be more than 6 charts"));
    } else {
      dispatch(
        login(
          { phone: phoneNumber, password },
          setStack,
          setShow,
          setError,
          setLoading,
          callBack
        )
      );
    }
  };

  console.log("ERROR", error?.status);
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="wp-user-form webkit"
        id="ControlForm_43687"
      >
        <div className="input-filed">
          <i className="icon-phone" />
          <input
            autoFocus
            type="text"
            name="phone"
            placeholder={t("Auth.PhoneNumber")}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <p className="error-message-login">
            {error?.data?.errors?.phone ? error?.data?.errors?.phone[0] : null}
          </p>
        </div>
        <div className="input-filed">
          <i className="icon-unlock-alt" />
          <input
            type="password"
            name="password"
            placeholder={t("Auth.Password")}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="error-message-login">
            {error?.data?.errors?.password
              ? error?.data?.errors?.password[0]
              : null}
          </p>
        </div>
        <div className="forget-password">
          <a
            data-toggle="tab"
            href="#user-password"
            className="cs-forgot-switch forgot-switch"
            onClick={() => setStack("forget-password")}
          >
            {t("Auth.ForgotPassword")}
          </a>
        </div>
        <button className="auth-button">
          {loading ? <BiLoaderAlt className="login-loader" /> : t("Auth.Login")}
        </button>
      </form>
      {error?.status === 401 ? (
        <p className="error-message">{t("Auth.WrongPhone")}</p>
      ) : null}
      <div className="signin-tab-link forget-password">
        {t("Auth.NewHere")}
        <a
          data-toggle="tab"
          href="#user-register"
          className="foodbakery-dev-login-box-btn forgot-switch"
          onClick={() => setStack("register")}
        >
          <span> </span>
          {t("Auth.Signup")}
        </a>
      </div>
    </div>
  );
};

export default Login;
