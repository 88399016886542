import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Info() {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user?.user?.user);
  console.log("USER55555555", user);
  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <div style={{ width: "100%" }} className="user-dashboard loader-holder">
        <div className="user-holder">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="element-title has-border reviews-header right-filters-row"></div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex-center">
              <div className="user-reviews-list">
                <div className="review-listing">
                  <ul>
                    <h6 className="info-header">
                      <span>{t("Profile.Name")}:</span>{" "}
                      {localStorage.getItem("userName")}
                    </h6>
                    <h6 className="info-header">
                      <span>{t("Auth.Email")}:</span>{" "}
                      {localStorage.getItem("userEmail")}
                    </h6>
                    <h6 className="info-header">
                      <span>{t("Auth.PhoneNumber")}:</span>{" "}
                      {localStorage.getItem("userPhone")}
                    </h6>
                    <h6 className="info-header">
                      <span>{t("Shared.WalletBalance")}:</span> {user?.balance}
                    </h6>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
