import React from "react";
import { Link } from "react-router-dom";

class HeaderLogo extends React.Component {
  render() {
    return (
      <div className="logo">
        <figure>
          <Link to="/" className="light-logo">
            <img
              style={{ height: "60px" }}
              src="/assets/extra-images/xchif.png"
              alt="XChef"
            />
          </Link>
          <Link to="/" className="dark-logo">
            <img
              style={{ height: "60px" }}
              src="/assets/extra-images/xchif.png"
              alt="XChef"
            />
          </Link>
        </figure>
      </div>
    );
  }
}

export default HeaderLogo;
