import React from "react";
import { useDispatch } from "react-redux";
import { getLikes, likeMeal } from "../../../redux/actions/cartActions";
import AppModal from "../../Modal";

const FavMeal = ({ meal }) => {
  const [show, setShow] = React.useState(false);
  const [isFav, setIsFav] = React.useState(meal?.is_in_wishlist);
  const emptyFunc = () => {};
  const callBack = () => {
    dispatch(getLikes(emptyFunc));
  };
  React.useEffect(() => {
    setIsFav(meal?.is_in_wishlist);
  }, [meal]);
  const dispatch = useDispatch();
  return (
    <div className="menu-itam-holder">
      <AppModal meal={meal} mealId={meal?.id} show={show} setShow={setShow} />
      <div className="menu-itam-list">
        <ul>
          <li>
            <div className="image-holder">
              {" "}
              <a>
                <img src={meal?.photos[0]?.photo_original_path} alt="#" />
              </a>
            </div>
            <div className="text-holder">
              <h6>{meal?.name}</h6>
              <span>{meal?.ingredients}</span>
              <a
                style={{ color: "#6e2230" }}
                onClick={() =>
                  dispatch(likeMeal(meal?.id, isFav, setIsFav, callBack))
                }
              >
                <i className={isFav ? "icon-heart" : "icon-heart-o"} />{" "}
              </a>
            </div>
            <div className="price-holder">
              <span className="price">{meal?.min_price} CA$</span>
              <a
                href="#."
                data-toggle="modal"
                data-target="#extras-0-1"
                className="dev-adding-menu-btn"
                onClick={() => setShow(true)}
              >
                <i className="icon-plus4 text-color" />
              </a>
              <span id="add-menu-loader-0" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FavMeal;
