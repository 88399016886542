import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppSpinner from '../../components/Spinner/Spinner';
import Banner from '../../components_home/Banner';
import MostPopularSection from '../../components_home/MostPopularSection';
import { getOffers } from '../../redux/actions/mealsActions';

function Offers() {
    const [localCart, setLocalCart] = React.useState(localStorage.getItem('localCart') ? JSON.parse(localStorage.getItem('localCart')) : []);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        dispatch(getOffers(setLoading))
    }, [])
    const offers = useSelector(state => state?.meals?.offers?.offers)
    console.log("OFF", offers)
    return (
        <div>
            <Banner offers />
            {
                loading?
                <div className='flex-center'><AppSpinner /></div> :
                    <MostPopularSection localCart={localCart} setLocalCart={setLocalCart} offers meals={offers} />
            }
        </div>
    )
}

export default Offers