import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getLikes, likeCreator } from '../../../redux/actions/cartActions';

function FavoriteItem({creator}) {
    const dispatch = useDispatch();
    const [isFav, setIsFav] = React.useState();
    const emptyFunc = () => {

    }
    const callBack = () => {
        dispatch(getLikes(emptyFunc))
    }
    const {t} = useTranslation();
  return (
      <li>
          <div className="suggest-list-holder">
              <div className="img-holder">
                  <figure>
                      <a href="listings.html">
                          <img src={creator?.likeable?.logo_original_path} alt="" />
                      </a>
                  </figure>
              </div>
              <div className="text-holder">
                  <div className="post-title">
                      <h5><a href="listings.html">{creator?.likeable?.name}</a></h5>
                  </div>
                  <div className="delivery-potions">
                      <span>{creator?.likeable?.address}</span>
                  </div>
                  {/* <span>Apple Juice</span> */}
                  <div className="list-option">
                      <Link to={`/view-creator/${creator?.likeable?.id}`} className="viewmenu-btn">{t("Shared.ViewMenu")}</Link>
                      <a onClick={() => dispatch(likeCreator(creator?.likeable?.id, isFav, setIsFav, callBack))} className="short-icon delete-shortlist"><i className="icon-close2"></i></a>
                  </div>
              </div>
          </div>
      </li>
  )
}

export default FavoriteItem