import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import  store  from "./redux/store";
import AppWrapper from './components_home/AppWrapper';
import AuthModal from './components_home/AuthModal';
// import { HomePage } from './pages/home';
import App from './App';
import './main.css'

ReactDOM.render(
  <Suspense fallback='loading...'>
    <Provider store={store}>
      <Router>
        <AppWrapper>
          <App />
        </AppWrapper>
        <AuthModal />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
