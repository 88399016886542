import React from "react";
import MealCreatorCenterSectionTabsHolderTabs from "./MealCreatorCenterSectionTabsHolderTabs";
import MealCreatorCenterSectionTabsHolderTab1 from "./MealCreatorCenterSectionTabsHolderTab1";
import MealCreatorCenterSectionTabsHolderTab2 from "./MealCreatorCenterSectionTabsHolderTab2";
import MealCreatorCenterSectionTabsHolderTab3 from "./MealCreatorCenterSectionTabsHolderTab3";

const MealCreatorCenterSectionTabsHolder = ({localCart, setLocalCart, categories, creator}) => {
  return (
    <div className="col-lg-7 col-md-7 col-sm-9 col-xs-12">
      <div className="tabs-holder horizontal">
        <MealCreatorCenterSectionTabsHolderTabs></MealCreatorCenterSectionTabsHolderTabs>
        <div className="tab-content">
          <MealCreatorCenterSectionTabsHolderTab1 localCart={localCart} setLocalCart={setLocalCart} categories={categories}></MealCreatorCenterSectionTabsHolderTab1>
          <MealCreatorCenterSectionTabsHolderTab2 creator={creator}></MealCreatorCenterSectionTabsHolderTab2>
          <MealCreatorCenterSectionTabsHolderTab3 creator={creator}></MealCreatorCenterSectionTabsHolderTab3>
        </div>
      </div>
    </div>
  );
}

export default MealCreatorCenterSectionTabsHolder;
