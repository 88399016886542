import React from "react";
import HeaderLogo from "./HeaderLogo";
// import HeaderLeftList from "./HeaderLeftList";

class HeaderLeft extends React.Component {
  render() {
    return (
      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <HeaderLogo></HeaderLogo>
        {/* <div className="main-location">
          <ul>
            <HeaderLeftList></HeaderLeftList>
            <li className="location-has-children choose-location">
              <a href="#">
                <i className="icon-location-pin2" />
                Choose location
              </a>
              <ul>
                <li className="select-location">
                  <div className="foodbakery-locations-fields-group">
                    <form action="#">
                      <span
                        id="foodbakery_radius_location_open"
                        className="foodbakery-radius-location"
                      >
                        <i className="icon-target5" />
                      </span>
                      <span
                        className="foodbakery-input-cross foodbakery-input-cross-header"
                        style={{
                          display: "none"
                        }}
                      >
                        <i className="icon-cross" />
                      </span>
                      <input
                        type="text"
                        defaultValue=""
                        name="location"
                        placeholder="All Locations"
                        autoComplete="off"
                      />
                      <span>
                        <span className="loc-icon-holder">
                          <i className="icon-target3" />
                        </span>
                      </span>
                      <div
                        className="select-location"
                        id="foodbakery-radius-range622192"
                        style={{
                          display: "none"
                        }}
                      >
                        <div className="select-popup popup-open">
                          <a
                            id="location_close_popup622192"
                            href="#"
                            className="location-close-popup"
                          >
                            <i className="icon-times" />
                          </a>
                          <input
                            type="hidden"
                            className="foodbakery-radius"
                            name="foodbakery_radius"
                            defaultValue={10}
                          />
                          <p>Show with in</p>
                          <input
                            id="ex16b313324"
                            type="text"
                            data-value={10}
                            defaultValue={10}
                            style={{
                              display: "none"
                            }}
                          />
                          <span>
                            Miles:{" "}
                            <span id="ex16b313324CurrentSliderValLabel">
                              10
                            </span>
                          </span>
                          <br />
                          <p className="my-location">
                            of <i className="cs-color icon-location-arrow" />
                            <a
                              id="foodbakery-geo-location-all"
                              className="cs-color foodbakery-geo-location313324"
                              href="#"
                            >
                              My location
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="popular-location">Popular Locations</li>
                <li>
                  <ul>
                    <li>
                      <a href="#">Acle</a>
                    </li>
                    <li>
                      <a href="#">Cheshire</a>
                    </li>
                    <li>
                      <a href="#">Crewe</a>
                    </li>
                    <li>
                      <a href="#">Devon</a>
                    </li>
                    <li>
                      <a href="#">England</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}
      </div>
    );
  }
}

export default HeaderLeft;
