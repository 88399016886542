import React from "react";

class MealCreatorTopSectionBannerRight extends React.Component {
  render() {
    return (
      <div className="delivery-timing reviews-sortby">
        <div className="text">
          <i className="icon-motorcycle" />
          <p>
            Delivery fee: £10.00
            <span>Min Order : £10.00 Max Order : £50.00</span>
          </p>
        </div>
        <ul>
          <li>
            <a href="#" className="reviews-sortby-active">
              <span>Today :</span>
              11:00 am - 11:00 pm <i className="icon-chevron-small-down" />
            </a>
            <ul className="delivery-dropdown">
              <li>
                <a href="#">
                  <span className="opend-day">Monday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Tuesday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Wednesday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Thursday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Friday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Saturday</span>{" "}
                  <span className="opend-time">
                    <small>:</small> 11:00 am - 11:00 pm
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="opend-day">Sunday</span>{" "}
                  <span className="opend-time close-day">
                    <small>:</small>Closed
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default MealCreatorTopSectionBannerRight;
