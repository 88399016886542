import React from "react";
import { FaNutritionix } from 'react-icons/fa'

const SearchResultSectionLeftMenuFilterOptionsNutri = ({nutri, selectedNutries, setSelectedNutries}) => {
  const [open, setOpen] = React.useState(false);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      setSelectedNutries(selectedNutries.concat([id]))
    } else {
      setSelectedNutries(selectedNutries.filter(row => row !== id))
    }
  }
  return (
    <div className="filter-holder panel-default">
      <div onClick={() => setOpen(!open)} className="filter-heading">
        <h6>
          <FaNutritionix className="filter-icon" />
          Nutri Profiles
        </h6>
      </div>
      <div className={open ? 'select-categories restaurant_pre_order' : 'select-categories restaurant_pre_order hide-mini'}>
        <ul className="filter-list cs-parent-checkbox-list">
          {
            nutri?.map((row, index) => {
              return(
                <li key={index}>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={`nutri${index}`}
                      name="restaurant_pre_order_checkbox"
                      className="restaurant_pre_order_yes"
                      defaultValue="yes"
                      onChange={(e) => handleChange(e, row.id)}
                    />
                    <label htmlFor={`nutri${index}`}>
                      {row.name}
                      {/* <span>(11)</span> */}
                    </label>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SearchResultSectionLeftMenuFilterOptionsNutri;
