import React from "react";
import SearchResultSectionLeftMenuFilterOptions from "./SearchResultSectionLeftMenuFilterOptions";

const SearchResultSectionLeftMenu = ({setData}) => {
  const [filterData, setFilterData] = React.useState();
  React.useEffect(() => {
    setData(filterData)
  }, [filterData])
  console.log("FILTERR", filterData)
  return (
    <aside className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
      <div className="">
        <SearchResultSectionLeftMenuFilterOptions setFilterData={setFilterData}></SearchResultSectionLeftMenuFilterOptions>
      </div>
    </aside>
  );
}

export default SearchResultSectionLeftMenu;
