import React from "react";
import FeaturedMealCreatorSectionMealCreator1 from "./FeaturedMealCreatorSectionMealCreator1";
import { t } from "i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1250, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const FeaturedMealCreatorSectionList = ({ creators }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="listing fancy fancy-simple">
        {Array.isArray(creators) && (
          <div>
            {creators?.length === 0 ? (
              <h3 className="text-center">{t("Shared.NoResults")}</h3>
            ) : (
              <Carousel responsive={responsive}>
                {creators?.map((creator, index) => (
                  <FeaturedMealCreatorSectionMealCreator1
                    key={index}
                    name={creator?.name}
                    address={creator?.address}
                    logo={creator?.logo_original_path}
                    minimum={creator?.delivery_from}
                    maximum={creator?.delivery_to}
                    phone={creator?.phone}
                    id={creator?.id}
                    creator={creator}
                  />
                ))}
              </Carousel>
            )}
          </div>
        )}
        <div className="flex-between">
          <div />
          <Link to="/meal-creators">
            <h6>
              View All <IoMdArrowForward fontSize="10px" />
            </h6>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedMealCreatorSectionList;
