import * as types from "../actions/types";

const initialState = {
  user: [],
  loading: false,
  likes: [],
  chat: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };

    case types.GET_CHAT:
      return {
        ...state,
        chat: action.payload?.reverse(),
      };
    case types.GET_CHAT_SCROLLEDd:
      return {
        ...state,
        chat: action.payload?.reverse()?.concat(state.chat),
      };
    case types.GET_ZONES:
      return {
        ...state,
        zones: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
