import React from "react";
import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { resendCode, verifyRegistration } from "../redux/actions/authActions";
import { myError } from "./Toast";

const VerifyCodeRegister = ({phone, setShow}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [code, setCode] = React.useState('');
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = (e) => {
        e.preventDefault()
        if(code.length !== 4) {
            myError(t('Toast.The code must be 4 charts'))
        } else {
            dispatch(verifyRegistration({ verify_code: code, phone: phone }, setShow, setError, setLoading))
        }
    }
    console.log("ERROR", error)
    return (
        <div>
            <form onSubmit={handleSubmit} method="post" className="wp-user-form demo_test">
                <div className="input-filed">
                    <i className="icon-user4" />
                    <input type="text" name='code' placeholder={t("Auth.EnterCode")} onChange={(e) => setCode(e.target.value)} />
                    {
                        error?.data?.errors?.verify_code ?
                            <p className="error-message-login">{error?.data?.errors?.verify_code[0]}</p> : null
                    }
                </div>
                {/* <div className="input-filed foodbakery-company-name">
                    <i className="icon-v-card" />
                    <input type="text" placeholder="Company Name" />
                </div>
                <div className="input-filed">
                    <i className="icon-v-card" />
                    <input type="text" placeholder="Display Name" />
                </div>
                <div className="input-filed">
                    <i className="icon-email" />
                    <input type="email" placeholder="Email" />
                </div> */}
                {/* <span className="signup-alert">
                    <b>Note :</b> Please enter your correct email and we
                    will send you a password on that email.
                </span> */}
                {/* <div className="checkbox-area">
                    <input
                        type="checkbox"
                        id="terms-head"
                        className="foodbakery-dev-req-field"
                    />
                    <label htmlFor="terms-head">
                        By Registering You Confirm That You Accept theme
                        <a target="_blank" href="#">
                            Terms & conditions
                        </a>
                        And
                        <a target="_blank" href="#">
                            Privacy Policy
                        </a>
                    </label>
                </div> */}
                <span onClick={() => dispatch(resendCode(phone))} className="resend-code">{t("Auth.ResendCode")}</span>
                <div className="side-by-side select-icon clearfix">
                    <div className="select-holder" />
                </div>
                <div className="checks-holder">
                    <button className="auth-button">{loading? <BiLoaderAlt className="login-loader" /> : t("Auth.Verify")}</button>
                </div>
            </form>
        </div>
    );
}

export default VerifyCodeRegister;
