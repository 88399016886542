import React from "react";
import { Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import { HomePage } from "./pages/home";
import { MealCreatorPage } from "./pages/mealcreator";
import { SearchPage } from "./pages/search";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { MealsPage } from "./pages/meals/meals";
import Profile from "./pages/profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import Offers from "./pages/offers/Offers";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import About from "./pages/about/About";
import Blogs from "./pages/blogs/Blogs";
import SingleBlog from "./pages/blogs/SingleBlog";
import "./responsive.css";
import "./overwrite.css";
import { getChat } from "./redux/actions/authActions";
import { getZones } from "./redux/actions/userActions";
import { getCart } from "./redux/actions/cartActions";

export const App = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  React.useEffect(() => {
    dispatch(getChat());
    dispatch(getZones());
    dispatch(getCart());
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/home-search/:searchKey" component={HomePage} />
        <Route exact path="/meal-creators" component={SearchPage} />
        <Route
          exact
          path="/view-creator/:creatorId"
          component={MealCreatorPage}
        />
        <Route
          exact
          path="/meal-creators/search/:searchKey"
          component={SearchPage}
        />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/meals" component={MealsPage} />
        <Route exact path="/meals/search/:searchKey" component={MealsPage} />
        <Route exact path="/mealss/two" component={MealsPage} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/about" component={About} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/view-blog/:blogId" component={SingleBlog} />

        {logged ? <Route exact path="/profile" component={Profile} /> : null}
      </Switch>
    </>
  );
};

export default App;
