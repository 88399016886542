import React from "react";
import SearchMainSearchForm from "./SearchMainSearchForm";

const SearchTopSection = ({ setSearchKey }) => {
  return (
    <div
      className="page-section nopadding cs-nomargin"
      style={{
        marginTop: "0px",
        paddingTop: "80px",
        paddingBottom: "60px",
        marginBottom: "0px",
        background:
          "url(/assets/extra-images/banner-img-2.jpg) no-repeat center top / cover",
      }}
    >
      {}
      <div className="container ">
        <div className="row">
          <div className="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                {}
                {}
                <div className="row">
                  {}
                  <div className="listing-main-search">
                    <SearchMainSearchForm
                      setSearchKey={setSearchKey}
                    ></SearchMainSearchForm>
                  </div>
                  {}
                </div>
                {}
              </div>
            </div>
            {}
          </div>
        </div>
      </div>{" "}
      {}
    </div>
  );
};

export default SearchTopSection;
