import * as types from '../actions/types'

const initialState = {
    creators: [],
    filters: [],
    loading: false,
    creator: {}
}

const creatorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CREATORS:
            return {
                ...state,
                creators: action.payload,
                loading: false
            };

        case types.GET_CREATOR_BY_ID:
            return {
                ...state,
                creator: action.payload,
                loading: false
            };


        case types.GET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };

        case types.CREATORS_LOADING:
            return {
                ...state,
                loading: true
            };
        
        default:
            return state
    }

}

export default creatorsReducer;