import React from "react";
import { useTranslation } from "react-i18next";
// import SearchResultSectionRightMenuSortListOption1 from "./SearchResultSectionRightMenuSortListOption1";
// import SearchResultSectionRightMenuSortListOption2 from "./SearchResultSectionRightMenuSortListOption2";
// import SearchResultSectionRightMenuSortListOption3 from "./SearchResultSectionRightMenuSortListOption3";
// import SearchResultSectionRightMenuSortListOption4 from "./SearchResultSectionRightMenuSortListOption4";
// import SearchResultSectionRightMenuSortListOption5 from "./SearchResultSectionRightMenuSortListOption5";
// import SearchResultSectionRightMenuSortListOption6 from "./SearchResultSectionRightMenuSortListOption6";

const SearchResultSectionRightMenuSortList = ({ setSort, sort }) => {
  const { t } = useTranslation();
  return (
    <div className="order-sort-results">
      <h5>{t("Cart.SortBy")}</h5>
      <ul>
        <li onClick={() => setSort("name")}>
          <a
            href="#"
            className="sort-by-alphabetical"
            style={
              sort === "name" ? { color: "#6e2230", fontWeight: "500" } : null
            }
          >
            <i className="icon-sort-alpha-asc" />
            {t("Cart.Name")}
          </a>
        </li>
        <li onClick={() => setSort("top_rated")}>
          <a
            href="#"
            className="sort-by-alphabetical"
            style={
              sort === "top_rated"
                ? { color: "#6e2230", fontWeight: "500" }
                : null
            }
          >
            {" "}
            <i className="icon-star-o" />
            {t("Cart.Rating")}
          </a>
        </li>
      </ul>
      {/* <ul>
        <SearchResultSectionRightMenuSortListOption1></SearchResultSectionRightMenuSortListOption1>
        <SearchResultSectionRightMenuSortListOption2></SearchResultSectionRightMenuSortListOption2>
        <SearchResultSectionRightMenuSortListOption3></SearchResultSectionRightMenuSortListOption3>
        <SearchResultSectionRightMenuSortListOption4></SearchResultSectionRightMenuSortListOption4>
        <SearchResultSectionRightMenuSortListOption5></SearchResultSectionRightMenuSortListOption5>
        <SearchResultSectionRightMenuSortListOption6></SearchResultSectionRightMenuSortListOption6>
      </ul> */}
    </div>
  );
};

export default SearchResultSectionRightMenuSortList;
