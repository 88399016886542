import axios from "axios";
import { myToast } from "../../components/Toast";
import * as types from "./types";

export const login =
  (loginData, setStack, setShow, setError, setLoading, callBack) =>
  (dispatch) => {
    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/login`, loginData)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: types.LOGIN,
          payload: res.data,
        });
        setStack("off");
        setShow(false);
        // myToast("Logged in successfully");
        localStorage.setItem(
          "userName",
          res?.data?.user?.first_name + " " + res?.data?.user?.last_name
        );
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("auth", true);
        localStorage.setItem("firstName", res?.data?.user?.first_name);
        localStorage.setItem("secondName", res?.data?.user?.last_name);
        localStorage.setItem(
          "isRegistered",
          res?.data?.user?.is_register_completed
        );
        localStorage.setItem("userEmail", res?.data?.user?.email);
        localStorage.setItem("userPhone", res?.data?.user?.phone);
        callBack();
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const verifyRegistration =
  (loginData, setShow, setError, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/verify-code`, loginData)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: types.LOGIN,
          payload: res.data,
        });
        setShow(false);
        myToast("Logged in successfully");
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("auth", true);
        localStorage.setItem(
          "userName",
          res?.data?.user?.first_name + " " + res?.data?.user?.last_name
        );
        localStorage.setItem(
          "isRegistered",
          res?.data?.user?.is_register_completed
        );
        localStorage.setItem("userEmail", res?.data?.user?.email);
        window.location.reload();
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const register =
  (phone, setStack, setError, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/register`, phone)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: "SS",
        });
        myToast(res.data?.code);
        setStack("verify-register");
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const forgetPassword =
  (phone, setStack, setError, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/forgot-password`, phone)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: "SS",
        });
        myToast(res.data?.code);
        setStack("forget-verify");
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const completeRegistration =
  (userData, setError, setLoading) => (dispatch) => {
    const formData = new FormData();
    formData.append("first_name", userData.firstName);
    formData.append("last_name", userData.lastName);
    formData.append("email", userData.email);
    formData.append("password", userData.password);
    formData.append("password_confirmation", userData.passwordConfirmation);
    formData.append("phone", userData.phone);

    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/complete-register`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        setLoading(false);
        dispatch({
          type: types.COMPLETE_REGISTERATION,
          payload: userData,
        });
        localStorage.setItem("isRegistered", 1);
        localStorage.setItem(
          "userName",
          userData.firstName + " " + userData.lastName
        );
        localStorage.setItem("userEmail", userData.email);
        window.location.reload();
        // myToast(res.data?.code)
        // setStack('verify-register')
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const forgetPasswordChange =
  (userData, setError, setLoading, setShow) => (dispatch) => {
    const formData = new FormData();
    formData.append("verify_code", userData.code);
    formData.append("phone", userData.phone);
    formData.append("password", userData.password);
    formData.append("password_confirmation", userData.passwordConfirmation);

    setLoading(true);
    axios
      .post(`${types.BASE_URL}/user/verify-forgot-password`, formData)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: types.LOGIN,
          payload: res.data,
        });
        setShow(false);
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("auth", true);
        localStorage.setItem(
          "userName",
          res?.data?.user?.first_name + " " + res?.data?.user?.last_name
        );
        localStorage.setItem(
          "isRegistered",
          res?.data?.user?.is_register_completed
        );
        localStorage.setItem("userEmail", res?.data?.user?.email);
        window.location.reload();
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
      });
  };

export const resendCode = (phone) => (dispatch) => {
  axios
    .post(`${types.BASE_URL}/user/resend-code`, { phone })
    .then((res) => {
      dispatch({
        type: "r",
      });
      myToast(res.data?.code);
    })
    .catch((err) => console.log(err));
};

export const logout = () => (dispatch) => {
  axios
    .post(
      `${types.BASE_URL}/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: types.LOGOUT,
      });
      localStorage.clear();
      window.location.reload();
    })
    .catch((err) => console.log(err));
};

export const getChat = () => (dispatch) => {
  axios
    .get(`${types.BASE_URL}/message/collection?limit=150`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) =>
      dispatch({
        type: types.GET_CHAT,
        payload: res.data.data,
      })
    );
};
export const getChatScrolled =
  (page, setScrollLoading, setCurrentPage) => (dispatch) => {
    setScrollLoading(true);
    axios
      .get(`${types.BASE_URL}/message/collection?page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCurrentPage((prevState) => prevState + 1);
        dispatch({
          type: types.GET_CHAT_SCROLLEDd,
          payload: res.data.data,
        });
      })
      .finally(() => setScrollLoading(false));
  };
export const getMealCreatorChat = (id) => (dispatch) => {
  axios
    .get(
      `${types.BASE_URL}/message/collection?conversation_id=${id}&limit=150`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) =>
      dispatch({
        type: types.GET_CHAT,
        payload: res.data.data,
      })
    );
};
