import * as types from "../actions/types";

const initialState = {
  cart: [],
  loading: false,
  likes: [],
  localCart: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CART:
      return {
        ...state,
        cart: action.payload,
        loading: false,
      };
    case types.CLEAR_CART:
      return {
        ...state,
        cart: {
          cart: [],
        },
        loading: false,
      };
    case types.LOCAL_CART_UPDATE:
      return {
        ...state,
        localCart: action.payload,
      };
    case types.GET_LIKES:
      return {
        ...state,
        likes: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default cartReducer;
