import React from 'react'
import ChangeEmail from './ChangeEmail'
import ChangeName from './ChangeName'
import ChangeNumber from './ChangeNumber'
import ChangePassword from './ChangePassword'

function AccountSettings() {
  return (
      <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div style={{ width: '100%' }} className="user-dashboard loader-holder">
              <div className="user-holder">
                  <div className="user-profile">
                      <div className="element-title has-border">
                          <h5>Account Settings</h5>
                      </div>
                      <div className="row">
                          <form id="publisher_profile">
                              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                  <ChangeName />
                              </div>
                             
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="opt-conts">
                                      <div className="row">
                                          <ChangeNumber />

                                          <ChangeEmail />

                                          <ChangePassword />
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default AccountSettings