import React from "react";
import MobileAppSectionInfoLeft from "./MobileAppSectionInfoLeft";
import MobileAppSectionRight from "./MobileAppSectionRight";

class MobileAppSectionInfo extends React.Component {
  render() {
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="main-post promo-box">
          <MobileAppSectionInfoLeft></MobileAppSectionInfoLeft>
          <MobileAppSectionRight></MobileAppSectionRight>
        </div>
      </div>
    );
  }
}

export default MobileAppSectionInfo;
