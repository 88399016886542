import React from "react";
import { useSelector } from "react-redux";
import CartOrderResturant from "../components_old/cart-order-resturant";
import LocalCart from "../components_old/cart-order-resturant/LocalCart";
import SearchResultSectionRightMenuSortList from "./SearchResultSectionRightMenuSortList";
// import SearchResultSectionRightMenuGreenBox from "./SearchResultSectionRightMenuGreenBox";
// import SearchResultSectionRightMenuOrangeBox from "./SearchResultSectionRightMenuOrangeBox";

const SearchResultSectionRightMenu = ({
  setSort,
  sort,
  localCart,
  setLocalCart,
}) => {
  const logged =
    localStorage.getItem("auth") === "true" ||
    useSelector((state) => state.auth.loggedIn) === true
      ? true
      : false;
  return (
    <div className="section-sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <SearchResultSectionRightMenuSortList
        sort={sort}
        setSort={setSort}
      ></SearchResultSectionRightMenuSortList>
      {logged ? (
        <CartOrderResturant />
      ) : (
        <LocalCart setLocalCart={setLocalCart} localCart={localCart} />
      )}
      {/* <SearchResultSectionRightMenuGreenBox></SearchResultSectionRightMenuGreenBox> */}
      {/* <SearchResultSectionRightMenuOrangeBox></SearchResultSectionRightMenuOrangeBox> */}
    </div>
  );
};

export default SearchResultSectionRightMenu;
