import React from "react";
import MealCreatorTopSectionBannerLeft from "./MealCreatorTopSectionBannerLeft";
import MealCreatorTopSectionBannerRight from "./MealCreatorTopSectionBannerRight";

const MealCreatorTopSectionBanner = ({creator}) => {
  return (
    <div className="company-info-detail">
      <MealCreatorTopSectionBannerLeft creator={creator}></MealCreatorTopSectionBannerLeft>
      <MealCreatorTopSectionBannerRight></MealCreatorTopSectionBannerRight>
    </div>
  );
}

export default MealCreatorTopSectionBanner;
