import React from "react";

class FooterBottomLeft extends React.Component {
  render() {
    return (
      <div className="copy-right">
        <p>
          © 2021 XChef. All Rights Reserved. Powered By{" "}
          <a href="https://techvillageco.com/">TechVillage Egypt</a>.
        </p>
      </div>
    );
  }
}

export default FooterBottomLeft;
