import React from "react";
import MealCreatorCenterSectionCategories from "./MealCreatorCenterSectionCategories";
import MealCreatorCenterSectionTabsHolder from "./MealCreatorCenterSectionTabsHolder";
import MealCreatorCenterSectionPlaceOrder from "./MealCreatorCenterSectionPlaceOrder";

const MealCreatorCenterSection = ({localCart, setLocalCart, categories, creator}) => {
  return (
    <div className="page-section">
      <div className="container">
        <div className="row">
          <MealCreatorCenterSectionCategories categories={categories}></MealCreatorCenterSectionCategories>
          <MealCreatorCenterSectionTabsHolder localCart={localCart} setLocalCart={setLocalCart} creator={creator} categories={categories}></MealCreatorCenterSectionTabsHolder>
          <MealCreatorCenterSectionPlaceOrder localCart={localCart} setLocalCart={setLocalCart}></MealCreatorCenterSectionPlaceOrder>
        </div>
      </div>
    </div>
  );
}

export default MealCreatorCenterSection;
