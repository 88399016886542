import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function BlogCard({blog}) {
    const history = useHistory();
  return (
      <div onClick={() => history.push(`/view-blog/${blog?.id}`)} className="col-lg-4 col-md-6 col-sm-6 col-xs-12 grid-item pointer">
          <div className="blog-post">
              <div className="img-holder">
                  <figure>
                      <img className='blog-card-img' src={blog?.photo_original_path} alt="#" />
                  </figure>
              </div>
              <div className="text-holder">
                  <div className="author-info">
                      <ul className="post-options">
                          <li><i className=" icon-clock4"></i> {new Date(blog?.created_at).toDateString()}
                          </li>
                      </ul>
                  </div>
                  <div className="post-title">
                      <h4>{blog?.title}</h4>
                  </div>
                  <a className="read-more text-color">Read Blog</a>
              </div>
          </div>
      </div>
  )
}

export default BlogCard