import axios from "axios";
import * as types from "./types";

export const getMeals =
  (filterData, sort, currentPage, limit, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .get(`${types.BASE_URL}/filter`, {
        params: {
          "categories[]": filterData?.categories?.map((category) => category),
          "cuisines[]": filterData?.cuisines?.map((category) => category),
          "nutritional_profiles[]": filterData?.nutries?.map(
            (category) => category
          ),
          "protein_types[]": filterData?.proteins?.map((category) => category),
          sort_by: sort,
          paginate: limit,
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.GET_MEALS,
          payload: res.data,
        });
      })
      .finally(() => setLoading(false));
  };

export const getOffers = (setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .get(`${types.BASE_URL}/offers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_OFFERS,
        payload: res.data,
      });
      setLoading(false);
    })
    .catch(() => setLoading(false));
};

export const mealsSearch =
  (searchKey, currentPage, limit, setLoading) => (dispatch) => {
    setLoading(true);
    axios
      .get(
        `${types.BASE_URL}/search?search_type=meal&search_text=${searchKey}&paginate=${limit}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.GET_MEALS,
          payload: res.data,
        });
      })
      .finally(() => setLoading(false));
  };

export const mealsLoading = () => (dispatch) => {
  dispatch({
    type: types.MEALS_LOADING,
  });
};
