import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

const FooterTopInfoPages = () => {
  const {t} = useTranslation();
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <div className="widget widget-categories">
        <div className="widget-title">
          <h5>{t("Footer.Links")}</h5>
        </div>
        <ul>
          <li>
            <li>
              <Link to="/terms"> {t("Footer.Terms")}</Link>
            </li>
          </li>
          <li>
            <Link to="/privacy"> {t("Footer.Privacy")}</Link>
          </li>
          <li>
            <Link to="/about"> {t("Footer.About")}</Link>
          </li>
          <li>
            <Link to="/meals"> {t("NavBar.Meals")}</Link>
          </li>
          <li>
            <Link to="/meal-Creators"> {t("NavBar.MealCreators")}</Link>
          </li>
          <li>
            <Link to="/blogs"> {t("NavBar.Blogs")}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterTopInfoPages;
