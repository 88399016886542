import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedMealCreatorSectionTitle = () => {
  const {t} = useTranslation();
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div
        className="cs-section-title"
        style={{
          textAlign: "center!important"
        }}
      >
        <h2>{t("Home.FeaturedMealCreators")}</h2>
      </div>
    </div>
  );
}

export default FeaturedMealCreatorSectionTitle;
