import React from "react";
import { useTranslation } from "react-i18next";

const BannerTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div className="column-content ">
        <b
          style={{
            fontSize: "50px",
            textTransform: "uppercase",
            lineHeight: "60px",
            textAlign: "center",
            display: "block",
            marginBottom: "30px",
            letterSpacing: "6px",
            color: "rgb(255, 255, 255)",
          }}
        >
          {t("NavBar.OrganicFast")}
          <br />
          {t("NavBar.EasyHealthy")}
        </b>
      </div>
    </div>
  );
};

export default BannerTitle;
