import React from "react";
import MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1 from "./MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1";

const MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList = ({localCart, setLocalCart, meal}) => {
  return (
    <ul>
      <MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1 localCart={localCart} setLocalCart={setLocalCart} meal={meal}></MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsListItem1>
    </ul>
  );
}

export default MealCreatorCenterSectionTabsHolderTab1MenuListCategoryItemsList;
