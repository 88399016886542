import React from "react";

class FooterBottomRight extends React.Component {
  render() {
    return (
      <div className="right-logos">
        <p>
          <img src="/assets/extra-images/footer-logo1.png" alt="" />
          <img src="/assets/extra-images/footer-logo2.png" alt="" />
          <img src="/assets/extra-images/footer-logo3.png" alt="" />
          <img src="/assets/extra-images/footer-logo4.png" alt="" />
          <img src="/assets/extra-images/footer-logo5.png" alt="" />
          <img src="/assets/extra-images/footer-logo6.png" alt="" />
        </p>
      </div>
    );
  }
}

export default FooterBottomRight;
