import React from "react";

const SearchResultSectionLeftMenuFilterOptionsCuisines = ({selectedCuisines, setSelectedCuisines, cuisines}) => {
  const [open, setOpen] = React.useState(false);
  const handleChange = (e, id) => {
    if(e.target.checked) {
      setSelectedCuisines(selectedCuisines.concat([id]))
    } else {
      setSelectedCuisines(selectedCuisines.filter(row => row !== id))
    }
  }
  return (
    <div className="filter-holder panel-default">
      <div onClick={() => setOpen(!open)} className="filter-heading">
        <h6>
          <i className="icon-food" />
          Cuisines
        </h6>
      </div>
      <div className={open ? 'select-categories' : 'select-categories hide-mini'}>
        <ul className="filter-list cs-checkbox-list">
          {
            cuisines?.map((row, index) => {
              return(
                  <li key={index}>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id={`cuisine${index}`}
                        className="foodbakery_restaurant_category"
                        onChange={(e) => handleChange(e, row.id)}
                      />
                      <label htmlFor={`cuisine${index}`}>{row.name}</label>
                      {/* <span>(5)</span> */}
                    </div>
                  </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default SearchResultSectionLeftMenuFilterOptionsCuisines;
